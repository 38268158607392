import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div style={bannerStyles}>
      <p>We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.</p>
      <button onClick={handleAccept} style={buttonStyles}>Accept</button>
      <button onClick={handleDecline} style={buttonStyles}>Decline</button>
    </div>
  );
};

const bannerStyles = {
  position: 'fixed',
  bottom: '0',
  width: '100%',
  backgroundColor: '#333',
  color: 'white',
  textAlign: 'center',
  padding: '10px',
};

const buttonStyles = {
  margin: '5px',
  padding: '10px',
  cursor: 'pointer',
};

export default CookieConsent;
