import React from 'react';
import ReactApexChart from 'react-apexcharts';
const sortByDate = (data) => {
  return data.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
};
const ApexGradientChartWithVolume = ({ data, lineColor }) => {
  const sortedData = sortByDate(data);
  const priceSeries = [
    {
      name: 'Adjusted Close Price',
      data: sortedData.map(chart => ({
        x: chart.date, // Keep date as a string
        y: chart.close,
      })),
    },
  ];

  const volumeSeries = [
    {
      name: 'Volume',
      data: sortedData.map(chart => ({
        x: chart.date, // Keep date as a string
        y: chart.volume,
      })),
    },
  ];

  const priceOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    colors: [lineColor], // Set the line color dynamically
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: [lineColor], // Dynamic gradient color
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    xaxis: {
      categories: data.map(chart => chart.date),
      tickAmount: 10,
      labels: {
        style: {
          colors: '#ffffff', // White labels
        },
        formatter: (value) => {
          const date = new Date(value);
          return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        },
      },
      reversed: false, // Ensure x-axis labels go from low to high
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff', // White labels
        },
        formatter: (value) => `$${value}`, // Format Y-axis with a dollar sign
      },
      crosshairs: {
        show: true, // Enable horizontal crosshair
      },
    },
    tooltip: {
      theme: 'dark', // Dark theme for tooltip
      y: {
        formatter: (value) => `$${value}`, // Format tooltip value with a dollar sign
      },
    },
  };

  const volumeOptions = {
    chart: {
      type: 'bar',
      height: 150,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Hide toolbar for volume chart
      },
    },
    colors: ['#00BFFF'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map(chart => chart.date),
      tickAmount: 10,
      labels: {
        style: {
          colors: '#ffffff', // White labels for volume chart
        },
        formatter: (value) => {
          const date = new Date(value);
          return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        },
      },
      reversed: false, // Ensure x-axis labels go from low to high
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ffffff', // White labels
        },
        formatter: (value) => `${value}`, // Format Y-axis for volume as a number
      },
      tickAmount: 2,
      crosshairs: {
        show: true, // Enable horizontal crosshair
      },
    },
    tooltip: {
      theme: 'dark', // Dark theme for tooltip
      y: {
        formatter: (value) => `${value}`, // Format tooltip value for volume
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        endingShape: 'rounded',
      },
    },
  };

  return (
    <div>
      {/* Volume Chart */}
      {/* <div id="volume-chart">
        <ReactApexChart options={volumeOptions} series={volumeSeries} type="bar" height={150} />
      </div> */}

      {/* Line Chart */}
      <div id="price-chart">
        <ReactApexChart options={priceOptions} series={priceSeries} type="line" height={350} />
      </div>
    </div>
  );
};

export default ApexGradientChartWithVolume;
