// src/pages/ChartPage.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header';
import Footer from '../../Components/Common/Footer';
import ApexGradientChartWithVolume from './LineChart';
import BarChart from './BarChart';
import PieChart from './PieChart';
import AreaChart from './AreaChart';
import { toast } from 'react-toastify';
// import 'chartjs-adapter-date-fns';
import { FaSearch ,FaEdit} from 'react-icons/fa'; // Import a search icon from react-icons
import ColumnChart from './ColumnChart';
import CandlestickChart from './CandlestickChart';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Ensure you import these icons
import { Navigate, useLocation,useNavigate } from "react-router-dom";


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666'
};

const darkTheme = {
  background: 'rgb(31 41 55)',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#ccc'
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top:10px;
`;

const MainLayout = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Sidebar = styled.div`
  width: ${({ collapsed }) => (collapsed ? '60px' : '250px')};
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  display: flex;
  flex-direction: column;
  padding: ${({ collapsed }) => (collapsed ? '20px 5px' : '20px')}; // Adjust padding for a more compact look
  position: fixed;
  top: 60px;
  height: calc(100vh - 50px);
  border-right: 2px solid ${({ theme }) => theme.color};
  transition: width 0.3s ease;
  overflow: hidden; // Prevent content overflow when collapsed
`;


const SidebarItem = styled.div`
  margin: 5px 0; // Reduced margin for a compact look
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  transition: color 0.3s ease;
  font-size: ${({ collapsed }) => (collapsed ? '0.8rem' : '1rem')}; // Adjust font size when collapsed
  opacity: ${({ collapsed }) => (collapsed ? '0.7' : '1')}; // Adjust opacity for visual effect

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;


const Checkbox = styled.input`
  margin-right: 10px;
`;

// const DateRangeSelector = styled.select`
//   padding: 10px;
//   margin-bottom: 20px;
//   border: 1px solid ${({ theme }) => theme.primary};
//   border-radius: 5px;
// `;

const MainContent = styled.div`
  margin-left: 270px;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative; /* Required for dropdown positioning */
`;

const SearchBarContainer = styled.div`
 
position: relative;
  width: 15%;
  margin-top:60px;
  // margin-bottom:20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 40px 10px 15px; /* Added padding to accommodate the icon */
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  transform: translateY(-50%);
  color: ${({ theme }) => theme.primary};
`;

const Dropdown = styled.div`
  position: 20px;
  // top: 110px; /* Adjust based on the SearchBar height */
  left: 0;
  width: 80%;
  max-height: 200px; /* Set the maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  z-index: 1000; /* Ensure dropdown appears above other content */
  color: ${({ theme }) => theme.background};
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color};
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.color};
  }
`;

const DropdownItem1 = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.background};
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.color};
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  flex-grow: 1;
`;

const ChartCard = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height:600px;
  // overflow:auto;
  width: 90%;
`;

const CompanyHeader = styled.h1`
  margin-bottom: 20px;
  margin-top: 20px;
  font-size:1.5em;
  color: ${({ theme }) => theme.color};
`;

const DateRangeButtonGroup = styled.div`
  display: flex;
  // justify-content: space-around;
  margin-bottom: 10px;
`;

const DateRangeButton = styled.button`
  background: ${({ active,theme }) => (active ? 'green' : theme.color)};
  // background: ${({ theme }) => theme.primary};
  border: 1px solid #000;
  border-radius: 4px;
  color: ${({theme}) => theme.background};
  cursor: pointer;
  padding: 10px 10px;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s, color 0.3s;

  &:last-of-type {
    margin-right: 0;
  }
  
  &:hover {
    background: #0056b3;
    color: #fff;
  }
`;

const ChartList = styled.div`
  margin-top: 20px;
`;
const ChartItem = styled.div`
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.secondary};
  color: white;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const EditButton = styled.button`
  position: fixed;
  right: 20px;
  top: 180px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const ColorInput = styled.input`
  margin-bottom: 10px;
  padding: 5px;
  width: 150px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 15%;
    margin-left: 10px; /* Space between dropdowns */
  
  
  // margin:10px;
`;

const DropdownButton = styled.button`
  background: ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.background};
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
`;

const DropdownList = styled.div`
  position: absolute;
  background: white;
  border: 1px solid ${({ theme }) => theme.primary};
  width: 100%;
  // max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

const SelectorContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Align items with space between */
  align-items: center; /* Center items vertically */
  width: 90%; /* Optional: adjust width as necessary */
`;


const DateRangeSelector = ({ value, onChange }) => (
  <DateRangeButtonGroup>
    {['1d', '30d', '3m','6m', '1y', '5y', 'ytd'].map(range => (
      <DateRangeButton
        key={range}
        active={value === range}
        onClick={() => onChange(range)}
      >
        {range === '1d' ? '1D' :
         range === '30d' ? '1M' :
         range === '3m' ? '3M' :
         range === '6m' ? '6M' :
         range === '1y' ? '1Y' :
         range === '5y' ? '5 Y' :
         'YTD'}
      </DateRangeButton>
    ))}
  </DateRangeButtonGroup>
);

const ChartPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchName, setSearchName] = useState('Apple Inc.');
  const [selectedSymbol, setSelectedSymbol] = useState('AAPL');
  const [charts, setCharts] = useState([]);
  const [showPanel, setShowPanel] = useState(false); // State to show/hide the chart panel
  const [lineColor, setLineColor] = useState('#FF0000'); // Default color is red
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme;
    }
  });

  const [selectedCharts, setSelectedCharts] = useState({
    Candlestick: false,
    Line: true,
    Bar: false,
    Pie: false,
    Area: false,
    Column:false,
    
  });

  const [dateRange, setDateRange] = useState('30d');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true); // Initialize the state
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [indicatorDropdownOpen, setIndicatorDropdownOpen] = useState(false);
  
  const [selectedChartType, setSelectedChartType] = useState(null);
  useEffect(() => {
    if (selectedSymbol) {
      fetchChartData(selectedSymbol, dateRange);
    }
  }, [selectedSymbol, dateRange]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen]);

  // Toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
  };
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [shouldNavigate, setShouldNavigate] = useState(false);
  useEffect(() => {
    if (shouldNavigate) {
      navigate('/indicators'); // Navigate to the desired route
      setShouldNavigate(false); // Reset state if necessary
    }
  }, [shouldNavigate, navigate]); // Include navigate in the dependencies

  const toggleIndicatorDropdown = () => {
    console.log('hello');
    
    // setShouldNavigate(true); // Set to true to trigger navigation
    setIndicatorDropdownOpen(prev => !prev);
  };


  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const fetchChartData = async (symbol, range) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=2010-01-01`);
      const data = response.historical.map(item => ({
        date: item.date,
        adjClose: item.adjClose,
        volume: item.volume,
        open: item.open,
        high: item.high,
        low: item.low,
      }));
      
      // Filter data based on selected range
      const now = new Date();
      let startDate;

      switch (range) {
        case '1d':
          startDate = new Date();
          break;
          // startDate.setDate(now.getDate() - 30);
        case '30d':
          startDate = new Date();
          startDate.setDate(now.getDate() - 30);
          break;
        case '3m':
          startDate = new Date();
          startDate.setMonth(now.getMonth() - 3);
          break;
        case '6m':
          startDate = new Date();
          startDate.setMonth(now.getMonth() - 6);
          break;
        case '1y':
          startDate = new Date();
          startDate.setFullYear(now.getFullYear() - 1);
          break;
        case '5y':
          startDate = new Date();
          startDate.setFullYear(now.getFullYear() - 5);
          break;
        case 'ytd':
          startDate = new Date();
          startDate.setFullYear(now.getFullYear() - 10);
          break;        
        default:
          startDate = new Date();
          
      }

      const filteredData = data.filter(item => new Date(item.date) >= startDate);
      
      setCharts(filteredData);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePanel = () => {
    setShowPanel(!showPanel);
  };

  const addChart = (chartType) => {
    setSelectedCharts((prev) => [...prev, chartType]);
    togglePanel(); // Close the panel after adding the chart
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length > 2) {
      await fetchSearchResults(query);
    } else {
      setSearchResults([]);
    }
  };

  const handleSelectChartType = (chartType) => {
    setSelectedChartType(chartType);
    handleCheckboxChange(chartType);
    setDropdownOpen(false); // Close dropdown after selection
  };

  const handleSearchSelect = (res) => {
    setSelectedSymbol(res.symbol);
    setSearchTerm('');
    setSearchName(res.name + " - " +res.symbol);
    setSearchResults([]);
    setSelectedCharts(prevState => ({
        ...prevState,
        ['Line']: true
      }));
  };

  const handleCheckboxChange = (chartType) => {
    setSelectedCharts({
      Candlestick: false,
      Line: false,
      Bar: false,
      Pie: false,
      Area: false,
      Column: false,
      [chartType]: true,
    });
  };
  

  const handleDateRangeChange = (e) => {
    setDateRange(e);
  };
  const toggleSidebar = (value) => {
    console.log('hello');
  
    // If a value is passed, set the sidebar state to that value
    if (typeof value === 'boolean') {
      setSidebarOpen(value);
    } else {
      // Otherwise, toggle the sidebar open/closed state
      setSidebarOpen(prev => !prev);
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen]);
  

  const renderChart = (option) => {
    switch (option) {
      case 'Candlestick':
        return <CandlestickChart data={charts} />;
      case 'Line':
        return <ApexGradientChartWithVolume data={charts} lineColor={lineColor}  selectedIndicator={selectedIndicator} symbol={selectedSymbol}/>;
      case 'Bar':
        return <BarChart data={charts} />;
      case 'Pie':
        return <PieChart data={charts} />;
      case 'Area':
        return <AreaChart data={charts} />;        
      case 'Column':
        return <ColumnChart data={charts} />;
      
      default:
        return <div style={{ color: '#000' }}>Chart not available</div>;
    }
  };
  const sidebarRef = useRef(null); // Reference for the sidebar
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && sidebarOpen) {
      setSidebarOpen(false);
    }
  };

  const indicators = [
    { label: 'Moving Average', value: 'SMA' },
    { label: 'Exponential Moving Average', value: 'EMA' },
    { label: 'Weighted Moving Average', value: 'WMA' },
    { label: 'Simple Moving Average', value: 'SMA' },
    { label: 'Moving Average Convergence Divergence', value: 'MACD' },
    { label: 'Relative Strength Index', value: 'RSI' },
    // { label: 'Stochastic Oscillator', value: 'Stochastic' },
    // { label: 'Momentum Indicator', value: 'Momentum' },
    { label: 'Rate of Change', value: 'ROC' },
    { label: "Williams %R", value: 'Williams_R' },
    { label: 'Bollinger Bands', value: 'BB' },
    { label: 'Average True Range', value: 'ATR' },
    { label: 'Keltner Channels', value: 'KC' },
    { label: 'On-Balance Volume', value: 'OBV' },
    // { label: 'Chaikin Money Flow', value: 'CMF' },
    { label: 'Accumulation/Distribution Line', value: 'ADL' },
    { label: 'ADX (Average Directional Index)', value: 'ADX' },
    // { label: 'Aroon Indicator', value: 'Aroon' },
    { label: 'Force Index', value: 'Force_Index' },
    { label: 'Fibonacci Retracement', value: 'Fibonacci' },
    { label: 'Elliott Wave Theory', value: 'Elliott_Wave' },
    { label: 'Cycle Indicators', value: 'Cycle' },
    { label: 'Candlestick Patterns', value: 'Candlestick' },
    { label: 'Pivot Points', value: 'Pivot' },
    { label: 'Support and Resistance Levels', value: 'S&R' },
    { label: 'Ichimoku Cloud', value: 'Ichimoku' },
    { label: 'Parabolic SAR', value: 'SAR' },
    { label: 'Donchian Channels', value: 'Donchian' },
  ];
  
  const [selectedIndicator, setSelectedIndicator] = useState('');
  const handleChange = (e) => {
    
    
    console.log(e);
    
    setSelectedIndicator(e);
    setIndicatorDropdownOpen(false);
  };  

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <MainLayout>
        {/* <Sidebar ref={sidebarRef} collapsed={!sidebarOpen} onClick={() => toggleSidebar(true)}> */}
  {/* <EditButton > */}
    {/* {sidebarOpen ? <FaChevronLeft /> : <FaChevronRight onClick={toggleSidebar}/>} */}
  {/* </EditButton> */}

  {/* {sidebarOpen && ( */}
    <>
        {/* <DropdownContainer>
                <DropdownButton onClick={toggleDropdown}>
                  {selectedChartType || 'Select Chart Type'}
                </DropdownButton>
                {dropdownOpen && (
                  <DropdownList>
                    {['Candlestick', 'Line', 
                    // 'Bar', 'Pie', 'Area', 'Column'
                  ].map(chartType => (
                      <DropdownItem1
                        key={chartType}
                        onClick={() => handleSelectChartType(chartType)}
                      >
                        {chartType}
                      </DropdownItem1>
                    ))}
                  </DropdownList>
                )}
              </DropdownContainer> */}

      {/* <label htmlFor="colorPicker">Select Line Color: </label> */}
      {/* <ColorInput
        id="colorPicker"
        type="color"
        value={lineColor}
        onChange={(e) => setLineColor(e.target.value)}
      /> */}
      {/* <DropdownContainer>
      <select value={selectedIndicator} onChange={handleChange} onClick={toggleDropdown}>
      <option value="">Select Indicator</option>
      {indicators.map((indicator, index) => (
        <option key={index} value={indicator.value}>
          {indicator.label} ({indicator.value})
        </option>
      ))}
    </select>
    </DropdownContainer> */}
    </>
    
  {/* )} */}
{/* </Sidebar> */}

          <MainContent>
          <SearchBarContainer>
            <SearchInput
            type="text"
            placeholder="Search for a symbol (e.g., AAPL)"
            value={searchTerm}
            onChange={handleSearchChange}
            />
            <SearchIcon />
        </SearchBarContainer>
        
        <CompanyHeader>{searchName}</CompanyHeader>
        {/* <EditButton onClick={togglePanel}>
          <FaEdit />
        </EditButton> */}
  {searchName && (
  <SelectorContainer>
    <DateRangeSelector value={dateRange} onChange={handleDateRangeChange} />
    
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        {selectedChartType || 'Select Candle'}
      </DropdownButton>
      {dropdownOpen && (
        <DropdownList>
          {['Candlestick', 'Line'].map(chartType => (
            <DropdownItem1
              key={chartType}
              onClick={() => handleSelectChartType(chartType)}
            >
              {chartType}
            </DropdownItem1>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>

    <DropdownContainer>
      {/* <DropdownButton onClick={toggleIndicatorDropdown}>
        {selectedIndicator || 'Select Indicator'}
      </DropdownButton> */}
      {indicatorDropdownOpen && (
        <DropdownList>
          <DropdownItem1 onClick={() => handleChange}>Select Indicator</DropdownItem1>
          {indicators.map((indicator, index) => (
            <DropdownItem1
              key={index}
              onClick={() => handleChange(indicator.value)}
            >
              {indicator.label} ({indicator.value})
            </DropdownItem1>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  </SelectorContainer>
)}


            
            {searchResults.length > 0 && (
              <Dropdown>
                {searchResults.map(result => (
                  <DropdownItem key={result.symbol} onClick={() => handleSearchSelect(result)}>
                    {result.symbol} - {result.name}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
            {/* {loading && <div>Loading data...</div>} */}
            {searchName ? 
            <ChartCard>
  {Object.keys(selectedCharts).map(chartType => (
    selectedCharts[chartType] ? (
      <div key={chartType}>
        {/* <h3>{chartType}</h3> */}
        {renderChart(chartType)}
      </div>
    ) : null
  ))}
</ChartCard> : null}

          </MainContent>
        </MainLayout>
        <Footer />
      </Container>
      
      {/* <ChartList>
            {['Line', 'Bar', 'Pie', 'Area', 'Candlestick', 'Column'].map((chartType) => (
              <ChartItem key={chartType} onClick={() => addChart(chartType)}>
                {chartType} Chart
              </ChartItem>
            ))}
          </ChartList> */}
    </ThemeProvider>
  );
};

export default ChartPage;
