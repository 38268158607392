import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const NewsCard = styled.div`
  // background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  max-width: 100%;
`;

const NewsItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.captionColor};

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 15px;
  }

  h4 {
    font-size: 1.2em;
    margin: 0;
    color: ${({ theme }) => theme.primary};
  }

  p {
    margin: 5px 0;
    font-size: 0.9em;
  }

  a {
    color: ${({ theme }) => theme.secondary};
    text-decoration: none;
    font-size: 0.9em;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  button {
    background-color: ${({ theme }) => theme.primary};
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    &:disabled {
      background-color: ${({ theme }) => theme.captionColor};
      cursor: not-allowed;
    }
  }
`;

const NewsSection = ({ symbol }) => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const totalPages = 5;

  // Calculate date 10 days ago
  const getLast10DaysDate = () => {
    const today = new Date();
    const last10Days = new Date(today);
    last10Days.setDate(today.getDate() - 10);
    return last10Days.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
  };

  const fetchNews = async () => {
    try {
      const from = getLast10DaysDate();
      const response = await axios.get(
        `https://financialmodelingprep.com/api/v3/stock_news?tickers=${symbol}&page=${page}&from=${from}&apikey=11cd608ea5136b9fdb748e3a80c1724e&limit=10`
      );
      setNews(response);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <NewsCard>
      <h3>Latest News</h3>
      {news.map((item) => (
        <NewsItem key={item.id}>
          {item.image ? <img src={item.image} alt="news" /> : null}
          <div>
            <h4>{item.title}</h4>
            <p>{item.text}</p>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              Read more
            </a>
            <p><small>{new Date(item.publishedDate).toLocaleDateString()}</small></p>
          </div>
        </NewsItem>
      ))}
      <Pagination>
        {[...Array(totalPages).keys()].map((num) => (
          <button
            key={num + 1}
            onClick={() => handlePageChange(num + 1)}
            disabled={num + 1 === page}
          >
            {num + 1}
          </button>
        ))}
      </Pagination>
    </NewsCard>
  );
};

export default NewsSection;
