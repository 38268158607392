import React from "react";
import { Navigate } from "react-router-dom";

import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn';
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";


//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Dashboard from "../pages/Dashboard";
// import Homepage from "../pages/Homepage";
import Homepage from "../pages/Homepage1";
import Pricing from "../pages/Pricing";
import NewsSection from "../Components/Common/NewsSection";
import NewsSectionCrypto from "../Components/Common/NewsSectionCrypto";
import NewsSectionStock from "../Components/Common/NewsSectionStock";
import Services from "../pages/Services";
import AboutUs from "../pages/AboutUs";
import LoginPage from "../pages/Login/LoginPageTemp";
import NewsSectionForex from "../Components/Common/NewsSectionForex";
import Chart from "../pages/Charts/ChartPage";
import Market from "../pages/Market/ChartPage";
import Clients from "../pages/Clients";
import PortfolioPage from "../pages/Portfolio";
import Articles from "../pages/Articles";
import { SignIn } from "@clerk/clerk-react";
import Overall from "../pages/OverallPortfolio";
import TradingIndicatorChart from "../pages/Charts/TradingIndicator";
import GlobalPage from "../pages/Global";
import EconomicPage from "../pages/EconomicPage";
import Settings from "../pages/Settings";
import CompanySummary from "../pages/company/CompanySummary";
import IncomeStatement from "../pages/company/IncomeStatement";
import SocioEconomic from "../pages/SocioEconomic";



const authProtectedRoutes = [
  // { path: "/dashboard-analytics", component: <DashboardAnalytics /> },
  // { path: "/dashboard-crm", component: <DashboardCrm /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/chart", component: <Chart />  },
  { path: "/market", component: <Market />  },
];

const publicRoutes = [
  // Authentication Page
  {
    path: "/",
    exact: true,
    component: <Homepage />,
  },

  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <SignIn /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/pricing", component: <Pricing />  },
  { path: "/general", component: <NewsSection />  },
  { path: "/crypto", component: <NewsSectionCrypto />  },
  { path: "/forex", component: <NewsSectionForex />  },
  { path: "/stock", component: <NewsSectionStock />  },
  { path: "/services", component: <Services />  },
  { path: "/about", component: <AboutUs />  },
  { path: "/clients", component: <Clients />  },
  { path: "/portfolio", component: <PortfolioPage />  },
  { path: "/overall", component: <Overall />  },
  { path: "/articles", component: <Articles />  },
  { path: "/indicators", component: <TradingIndicatorChart />  },
  { path: "/political", component: <GlobalPage />  },
  { path: "/economic", component: <EconomicPage />  },
  { path: "/socioeconomic", component: <SocioEconomic />  },
  { path: "/settings", component: <Settings />  },
  { path: "/summary", component: <CompanySummary />  },
  { path: "/incomestatement", component: <IncomeStatement />  },
  
  
  
  { path: "*", component: <Navigate to="/" /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  
];

export { authProtectedRoutes, publicRoutes };