// src/components/charts/BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data }) => {
  const chartData = {
    labels: data.map(chart => chart.date),
    datasets: [
      {
        label: 'Volume',
        data: data.map(chart => chart.volume),
        backgroundColor: '#AC544C',
        borderColor: '#000',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
      y: {
        type: 'linear',
        ticks: {
          color: '#000',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#000',
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index', // Show vertical line across all datasets on hover
        intersect: false, // Tooltip triggers between data points
        callbacks: {
          label: function(tooltipItem) {
            return `Volume: ${tooltipItem.raw}`;
          },
        },
        displayColors: false, // Hide the color box in the tooltip
      },
    },
    hover: {
      mode: 'index', // Show vertical line on hover
      intersect: false,
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default BarChart;
