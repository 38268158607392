import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import JsVectorMap from 'jsvectormap';
import 'jsvectormap/dist/jsvectormap.css';
import 'jsvectormap/dist/maps/world.js'; // World map
import { supabase } from '../supabaseClient'; // Import your Supabase client
import Header from './Header';
import { VectorMap } from '@south-paw/react-vector-maps';
import { iso_codes, countryToIso } from '../assets/country_iso_codes';
import * as worldLowRes from '../common/world.svg.json';
import FlipCardComponent from './GlobalPageCards';
import Footer from '../Components/Common/Footer';

// Define light and dark themes
const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
};

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    // margin: 10%;
    height: 100%;
    transition: background-color 0.3s, color 0.3s;
  }
`;

// Main container
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  // height: 100vh;
  overflow-y: auto;
  margin-top: 100px;
  margin-left:10%;
  margin-right:10%;
  margin-bottom:5%;
`;

// Layout for search bar
const SearchBar = styled.input`
  width: 15%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  margin-bottom: 20px;
  outline: none;
  font-size: 16px;
`;

// Row for maps
const MapRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  min-height: 500px;  
`;

const Map = styled.div`
  width: 100%; 
  // height: 00px; // Set a fixed height
  // border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  // background: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  // margin-left:-5%;

  svg {
    width: 100%;
    max-height: 380px;
    stroke: #fff;
    stroke: #fff;
margin-top:5%;

    path {
      fill: #c0c0c0; // Default color for regions
      cursor: pointer;
      outline: none;

      &:hover {
        fill: rgba(168, 43, 43, 0.83); // Color on hover
      }
      &:focus {
        fill: rgba(168, 43, 43, 0.6);
      }
      &[aria-checked='true'] {
        fill: rgba(56, 43, 168, 0.83); // Selected region color
      }
      &[aria-current='true'] {
        fill: rgba(56, 43, 168, 0.83); // Current region color
      }
    }
  }
`;

// Individual map containers (card style)
const MapCard = styled.div`
  width: 100%;
  // border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  // background: ${({ theme }) => theme.background};
  padding: 20px;
  // margin-right:5%;
  // background: ${({ theme }) => theme.cardBackground};
  // margin-left:30px;

  svg {
    height: 400px;
    width: 100%;
    margin-top:auto;
    margin-bottom:auto;
    path {
      fill: #c0c0c0;
      cursor: pointer;
      outline: none;

      &:hover {
        fill: rgba(168, 43, 43, 0.83);
      }
      &:focus {
        fill: rgba(168, 43, 43, 0.6);
      }
      &[aria-checked='true'] {
        fill: rgba(168, 43, 43, 0.83);
      }
      // &[aria-current='true'] {
      //   fill: rgba(56, 43, 168, 0.83);
      // }
    }
  }
`;

// Dropdown for country suggestions
const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 40px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.cardBackground};
  width: 30%;
  position: absolute; // Absolute position to overlay suggestions
  z-index: 1;
  max-height: 200px; // Limit height
  overflow-y: auto; // Enable scrolling
`;

// Individual suggestion item
const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #7ebdc2; // Highlight on hover
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  pointer-events: none; // Prevent tooltip from interfering with mouse events
`;

const StyledHeading = styled.h3`
  text-align: center;
  margin: 10px;
  font-size: 1.5em;
  color: ${({ theme }) => theme.cardColor};
`;


const GlobalPage = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });


  const [searchQuery, setSearchQuery] = useState(localStorage.getItem('country') || 'United States');

  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('country') || 'United States'); // Default to USA (ISO code)
  const [countryValue, setCountryValue] = useState(null);
  const [countryMapInstance, setCountryMapInstance] = useState(null);
  const [worldMapInstance, setWorldMapInstance] = useState(null);
  const [code, setCode] = useState(localStorage.getItem('code') || 'usa');
  const [valueObj, setValueObj] = useState({});
  const [hovered, setHovered] = useState(null);
  const [hoveredW, setHoveredW] = useState(null);
  
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [lastUpdated, setLastUpdated] = useState('');

  
  // Fetch countries and values from Supabase when the component loads
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data, error } = await supabase.rpc('get_distinct_countries_with_values');
        if (error) {
          console.error("Error fetching countries:", error);
          return;
        }
        setCountries(data);
        setFilteredCountries(data);
        const countriesObject = data.reduce((acc, country) => {
          acc[country.country] = country.value; // Assuming country.value contains the value you want to store
          return acc;
        }, {});
        setValueObj(countriesObject);

        const lastUpdated = await supabase
        .from('CIA_Factbook')
        .select('LastUpdated', { count: 'exact' })
        .order('LastUpdated', { ascending: false })
        .limit(1);

        setLastUpdated(lastUpdated?.data[0].LastUpdated);
        setFilteredCountries([]);

      } catch (err) {
        console.error('Error:', err);
      }
    };

    fetchCountries();
  }, []);

  // Filter countries based on search query
  useEffect(() => {
    const result = countries.filter(country =>
      country.country.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCountries(result);
    
  }, [searchQuery, countries]);

  // Initialize and update maps when selectedCountry changes
  useEffect(() => {
    // Initialize the country-specific map
    const initializeCountryMap = async () => {
      setFilteredCountries([]);
      try {        
        let mapPath = await import(`../assets/maps/${selectedCountry.toLowerCase()}.json`);
        
        setCountryMapInstance(mapPath);
      } catch (error) {
        setCountryMapInstance(null);
      }
    };

    initializeCountryMap();
  }, [selectedCountry]);

  // Handle country selection from search results
  const handleCountryClick = (country,code) => {

    setFilteredCountries([]);
    setSelectedCountry(country); 
    
    
    
    setCountryValue(valueObj[country]); 
    
    setSearchQuery(country); // Clear search query after selection
    setFilteredCountries([]);
    if(!code && countryToIso[country])
      setCode(countryToIso[country].toLowerCase());  
    else
      setCode(code);    
    

  };

  const layerProps = {
    onMouseEnter: ({ target }) => {      
      
      const countryName = target.ariaLabel; // Get the country name
      setHovered(countryName);
      const rect = target.getBoundingClientRect(); // Get the position of the target element
      setTooltipPosition({
        top: rect.top - 30, // Position above the element
        left: rect.left + rect.width / 2, // Center it horizontally
      });
    },
    onMouseLeave: () => {
      setHovered(null);
    },
    onClick: ({ target }) => {
      // setSelectedCountryCode(target.id);
      setCode(target.id)
      setFilteredCountries([]);
      // setSelectedCountry(target.attributes.name.value);
    },
  };
  const layerPropsWorld = {
    onMouseEnter: ({ target }) => {
      console.log(code);
      
      const countryName = target.ariaLabel; // Get the country name
      setHoveredW(countryName);
      const rect = target.getBoundingClientRect(); // Get the position of the target element
      setTooltipPosition({
        top: rect.top - 30, // Position above the element
        left: rect.left + rect.width / 2, // Center it horizontally
      });
    },
    onMouseLeave: () => {
      setHoveredW(null);
    },
    onClick: ({ target }) => {
      console.log(target);
      
      // setSelectedCountryCode(target.id);
      setSelectedCountry(target.attributes.name.value);   
      setCode(target.id);
      setFilteredCountries([]);
      setSearchQuery(target.attributes.name.value);
      localStorage.setItem('country',target.attributes.name.value);      
      localStorage.setItem('code',target.id);      
         
    },
    
  };
  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />

        {/* Search bar */}
        <SearchBar
          type="text"
          placeholder="Search for a country"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Suggestion List for matching countries */}
        {searchQuery && filteredCountries.length > 0 && (
          <SuggestionList>
            {filteredCountries.map((country) => (
              <SuggestionItem key={country.country} onClick={() => handleCountryClick(country.country)}>
                {country.country}
              </SuggestionItem>
            ))}
          </SuggestionList>
        )}

        {/* Maps row */}
        <MapRow>
          {/* <MapCard> */}
            <Map>
            {/* <h3 style={{textAlign:'center',margin:'10px',fontSize:'1.5em'}}>{selectedCountry} Map</h3> */}
            <StyledHeading>{selectedCountry} Map</StyledHeading>
            {hovered && (
              
                <Tooltip style={{
                  top: tooltipPosition.top,
                  left: tooltipPosition.left,
                  transform: 'translate(-50%, -100%)' // Position above the cursor
                }}>
                  {hovered}
                </Tooltip>
              )}
            <VectorMap {...countryMapInstance} layerProps={layerProps} currentLayers={hovered}            
            />
            </Map>
          <MapCard>
            {/* <h3>World Map</h3> */}
            {/* <MapContainer id="world-map"></MapContainer> */}
            <VectorMap {...worldLowRes} layerProps={layerPropsWorld} currentLayers={hoveredW} checkedLayers={[code]}/>
          </MapCard>
        </MapRow>

        {/* Display selected country value */}
        
        <FlipCardComponent country={selectedCountry} background={theme.cardBackground}/>
        Source: CIA Factbook (Last updated: {lastUpdated})
        
        {/* {selectedCountry && (
          <CountryValue>
            <p>{valueObj[selectedCountry]}</p>
          </CountryValue>
        )} */}
        
      </Container>
      <Footer/>
    </ThemeProvider>
  );
};

export default GlobalPage;
