import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import styled from "styled-components";
import {toast, ToastContainer } from 'react-toastify';

// Container for the entire signup section
const Container = styled.footer`
  background-color: #222122;
  color: white;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  border-top: 1px solid #333;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 50px;
  }
`;

// Styles for the heading
const Heading = styled.h3`
  color: #ffffff;
  margin-bottom: 20px;
`;

// Form container
const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 90%;
`;

// Style for the labels
const Label = styled.label`
  margin-bottom: 5px;
  width: 50%;
  text-align: right;
  color: #f1f1f1;
  font-weight: bold;
  padding-right:10px;
`;

// Style for the input fields
const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// Style for the submit button
const Button = styled.button`
margin-left: 30px;  
margin-bottom: 12px;  
padding: 10px 20px;
  background-color: #AC544C;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

// Style for the message
const Message = styled.p`
  margin-top: 15px;
  margin-left: 15px;
  font-size: 1em;
  color: ${(props) => (props.error ? 'red' : 'green')};
`;

const NewsletterSignup = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { error } = await supabase
            .from('newsletter_signups')
            .insert([{ name, email }]);

            if (error) {
                console.log(error);
                if(error.message.includes("duplicate")){
                    toast.info('You are already subscribed');    
                }else{
                    toast.error('There was an error signing up. Please try again.');
                }
                
                
            } else {
                toast.success('Thank you for subscribing!');
                setName('');
                setEmail('');
            }
    };

    return (
        <Container>
            <Heading>Sign up for our newsletter</Heading>
            <Form onSubmit={handleSubmit}>
                <Label htmlFor="name">Name:</Label>
                <Input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />

                <Label htmlFor="email">Email:</Label>
                <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <Button type="submit">Subscribe</Button>
            </Form>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Container>
    );
};

export default NewsletterSignup;
