// DataComponent.js
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Header from '../../pages/Header/index';
import Footer from '../../Components/Common/Footer';
import { Line, Bar } from 'react-chartjs-2';
import { supabase } from '../../supabaseClient';
import GeographicMap from './GeographicMap';
import axios from 'axios';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
};


const Container = styled.div`
  text-align: left;
  padding-top: 50px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primary};
`;

const Text = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
`;

const Label = styled.label`
  margin:10px;
`;
const Input = styled.input`
  margin:5px;
`;


const IncomeStatement = () => {
  const [theme, setTheme] = useState(lightTheme);
  const [chartData, setChartData] = useState(null);
  const [revenueSegmentationData, setRevenueSegmentationData] = useState(null);
  const [geographicData, setGeographicData] = useState(null);
  const [symbol1, setSymbol1] = useState('AAPL');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [periodType, setPeriodType] = useState('annual');


  const fetchCompanyData = async (symbol) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/company/profile/${symbol1}?apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSelectedCompany(response);
  
    } catch (error) {
      console.error('Error fetching company data', error);
    }
  };


  // Fetch income statement data
  const fetchIncomeStatement = async () => {
    const { data, error } = await supabase.rpc('get_filtered_income_statement', {
      symbol_input: symbol1,
      interval_input: '5 years',
      period_filter: periodType === 'annual' ? true: false
    });

    if (error) {
      console.error('Error fetching data:', error);
    } else {
      setChartData(data);
    }
  };

  // Fetch revenue product segmentation data
  const fetchRevenueSegmentation = async () => {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v4/revenue-product-segmentation?symbol=${symbol1}&structure=flat&period=${periodType}&apikey=11cd608ea5136b9fdb748e3a80c1724e`
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        const currentDate = new Date();
      const fiveYearsAgo = new Date();
      fiveYearsAgo.setFullYear(currentDate.getFullYear() - 5);

      const filteredData = data.filter(item => {
        const itemDateKey = Object.keys(item)[0]; // Extract date key like "2024-09-28"
        const itemDate = new Date(itemDateKey);
        return itemDate >= fiveYearsAgo && itemDate <= currentDate;
      });

      // Transform the filtered data for use in a bar chart
      const transformedData = transformRevenueSegmentationData(filteredData);
      setRevenueSegmentationData(transformedData);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching revenue segmentation data:', error);
    }
  };

  // Transformation function to structure data for the bar chart
  const transformRevenueSegmentationData = (data) => {
    const labels = [];
    const products = {};
    const yearsWithData = new Set(); // Track which years have data

    // Process each entry
    data.forEach((entry) => {
        const [date, revenueData] = Object.entries(entry)[0];
        labels.push(date); // Collect the date
        yearsWithData.add(date); // Add the date to the set

        // Process each product's revenue data
        Object.entries(revenueData).forEach(([product, revenue]) => {
            if (!products[product]) {
                products[product] = {};
            }
            products[product][date] = revenue; // Store revenue for the specific date
        });
    });

    // Prepare final datasets with proper alignment
    const finalLabels = labels.reverse(); // Reverse to have chronological order
    const datasets = Object.keys(products).map(product => {
        const revenueData = finalLabels.map(year => products[product][year] || null); // Fill gaps with null
        return {
            label: product,
            data: revenueData,
            backgroundColor: getColorForProduct(product),
        };
    });

    return {
        labels: finalLabels,
        datasets: datasets,
    };
};



  const getColorForProduct = (product) => {
    const colors = {
      "Mac": "#b06252",
      "Service": "#AC544C",
      "Wearables, Home and Accessories": "#7D7C84",
      "iPad": "#567D89",
      "iPhone": "#8A5C8A",
      "Other": "#CCCCCC"
    };
    return colors[product] || "#999999";
  };

  const fetchGeographicData = async () => {
    const response = await fetch('https://financialmodelingprep.com/api/v4/revenue-geographic-segmentation?symbol=AAPL&structure=flat&period=annual&apikey=11cd608ea5136b9fdb748e3a80c1724e');
    const data = await response.json();
    setGeographicData(data);
  };

  useEffect(() => {
    fetchIncomeStatement();
    fetchRevenueSegmentation();
    fetchGeographicData(); // Fetch geographic data when the component mounts or symbol1 changes
  }, [symbol1, periodType]);

  // Prepare line chart data for income statement
  const dataForLineChart = chartData ? {
    labels: chartData.map(entry => entry.date),
    datasets: [{
      label: 'Revenue',
      data: chartData.map(entry => entry.revenue),
      borderColor: '#AC544C',
      backgroundColor: '#AC544C80',
      fill: true
    }]
  } : null;

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company.companyName);
    setSymbol1(company.symbol);
    fetchCompanyData(company.symbol);
  };

  const handlePeriodChange = (event) => {
    const { value } = event.target;
    setPeriodType(value);
};

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
          onCompanySelect={handleCompanySelect}
        />
        <ContentWrapper>
          {/* Income Statement Line Chart */}

          <Section>
            <Title>Select Data Period</Title>
            <Label>
                <Input 
                    type="checkbox" 
                    value="annual" 
                    checked={periodType === 'annual'} 
                    onChange={handlePeriodChange} 
                />
                Annual
            </Label>
            <Label>
                <Input 
                    type="checkbox" 
                    value="quarter" 
                    checked={periodType === 'quarter'} 
                    onChange={handlePeriodChange} 
                />
                Quarterly
            </Label>
          </Section>
          <Section>
            <Title>Income Statement Overview</Title>
            <Text>Revenue over time based on latest data:</Text>
            {dataForLineChart ? <Line data={dataForLineChart} /> : <p>Loading line chart...</p>}
          </Section>

          {/* Revenue Segmentation Bar Chart */}
          <Section>
            <Title>Revenue Product Segmentation</Title>
            <Text>Revenue breakdown by product:</Text>
            {revenueSegmentationData ? <Bar data={revenueSegmentationData} /> : <p>Loading bar chart...</p>}
          </Section>
          <Section>
          {/* <GeographicMap data={geographicData} /> */}
          </Section>
        </ContentWrapper>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default IncomeStatement;
