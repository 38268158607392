
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabaseClient'; // Import the Supabase client
import Modal from './GlobalPageCardModal'; // Import the Modal component

const CardContainer = styled.div`
  display: grid;
  // grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto; /* Allow rows to adjust based on content height */
  gap: 10px;
  padding-top: 20px;
  width: 100%
`;

const Card = styled.p`

  background-color: ${({ color }) => color};
  // color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  grid-column: ${({ column }) => column};
  grid-row: ${({ row }) => row};
  position: relative;
  height: auto; /* Allow the height to adjust based on content */
  min-height: 100px; /* Set minimum height */
  max-height: 400px; /* Set minimum height */
  overflow-y:auto;
`;

const ContentContainer = styled.p`
  max-height: 100%;
  overflow-y: auto;
  line-height:1.5;
  padding-top:20px;
  text-align:left; 
`;

const Title = styled.label`
  // font-size: 1.2em;
  font-weight:bold;
  // margin-bottom: 10px;
  // color: ${({ theme }) => theme.color};
`;

// Expandable Card Component
const FlipCardComponent = ({ country, background }) => {
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [cardData, setCardData] = useState({
    historicalSummary: [],
    legalSystem: '',
    organizationParticipation: '',
    government: [],
    military: [],
    citizenship: [],
    judicial: [],
    legislative: [],
    internationalParticipation: '',
    terroristGroup: []
  });

  const openModal = (index) => {
    setExpandedCardIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setExpandedCardIndex(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const fetchHistoricalSummary = await supabase
        //   .from('CIA_Factbook')
        //   .select('Value')
        //   .eq('Country', country)
        //   .eq('Variable', 'Background');
        
        const fetchHistoricalSummary = await supabase
          .from('CIA_Factbook')
          .select('Variable, Value')
          .eq('Country', country)
          .in('Variable', [
            'Country Name Abbreviation',
            'Country Name - Local Long Form',
            'Country Name - Etymology',
            'Capital Name',
            'National Anthem Name',
            'Country Name Former',
            'Background'
          ]);

        const fetchLegalSystem = await supabase
          .from('CIA_Factbook')
          .select('Value')
          .eq('Country', country)
          .eq('Variable', 'Legal System');

        const fetchOrganizationParticipation = await supabase
          .from('CIA_Factbook')
          .select('Value')
          .eq('Country', country)
          .eq('Variable', 'International Organization Participation');

        const fetchGovernment = await supabase
          .from('CIA_Factbook')
          .select('Variable, Value')
          .eq('Country', country)
          .in('Variable', ['Government Type', 'Head of Government', 'Political Parties']);

        const fetchMilitary = await supabase
          .from('CIA_Factbook')
          .select('Variable, Value')
          .eq('Country', country)
          .in('Variable', [
            'Military and Security Forces',
            'Military and Security Forces Note',
            'Military - Note',
            'Military Equipment Inventories and Acquisitions',
            'Military and Security Service Personnel Strengths',
            'Military Deployments',
            'Military Service Age and Obligation',
          ]);

        const legislative = await supabase
          .from('CIA_Factbook')
          .select('Variable, Value')
          .eq('Country', country)
          .in('Variable', [
            'Legislative Branch Description',
            'Legislative Branch Election Results',
            'Legislative Branch Note',
          ]);

        const judicial = await supabase
          .from('CIA_Factbook')
          .select('Variable, Value')
          .eq('Country', country)
          .in('Variable', [
            'Legal System',
            'Judicial Branch - Highest Court(s)',
            'Judicial Branch - Judge Selection and Term of Office',
            'Judicial Branch - Subordinate Courts',
            'Judicial Branch Note',
          ]);

        const citizenship = await supabase
          .from('CIA_Factbook')
          .select('Variable, Value')
          .eq('Country', country)
          .in('Variable', [
            'Citizenship by Birth',
            'Citizenship by Descent Only',
            'Dual Citizenship Recognized',
            'Residency Requirement for Naturalization',
            'Suffrage',
            'Suffrage Note',
          ]);

        const internationalParticipation = await supabase
          .from('CIA_Factbook')
          .select('Value')
          .eq('Country', country)
          .eq('Variable', 'International Law Organization Participation');

        const terroristGroup = await supabase
        .from('CIA_Factbook')
        .select('Variable, Value')
        .eq('Country', country)
        .in('Variable', [
          'Terrorist group(s)', 
          'Refugees and Internally Displaced Persons (Country of Origin)', 
          'Illicit Drugs'
        ]);
        

        setCardData({
          historicalSummary: fetchHistoricalSummary?.data.length
          ? fetchHistoricalSummary.data
          : [{ Value: 'No data available' }],
          legalSystem: fetchLegalSystem?.data[0]?.Value || 'No data available',
          organizationParticipation: fetchOrganizationParticipation?.data[0]?.Value || 'No data available',
          government: fetchGovernment?.data.length
            ? fetchGovernment.data
            : [{ Value: 'No data available' }],
          military: fetchMilitary?.data.length
            ? fetchMilitary.data
            : [{ Variable: '', Value: 'No data available' }],
          legislative: legislative?.data.length
            ? legislative.data
            : [{ Variable: '', Value: 'No data available' }],
          judicial: judicial?.data.length
            ? judicial.data
            : [{ Variable: '', Value: 'No data available' }],
          citizenship: citizenship?.data.length
            ? citizenship.data
            : [{ Variable: '', Value: 'No data available' }],
          internationalParticipation: internationalParticipation?.data[0]?.Value || 'No data available',
          terroristGroup: terroristGroup?.data.length
            ? terroristGroup.data
            : [{ Value: 'No data available' }],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [country]);

  // const splitTextIntoParagraphs = (text) => {
  //   return text.split(/\.{4}|[;]|(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|\!)(?=\s)/)
  //     .map(part => part.trim())
  //     .filter(Boolean);
  // };

  const splitTextIntoParagraphs = (text) => {
    const sentences = text.split('.');
    let paragraphArray = [];
    let paragraph = '';
    
    sentences.forEach((sentence, index) => {
      if (sentence.trim()) {
        // Rebuild the sentence with the period
        paragraph += sentence.trim() + '.';
  
        // If 4 full stops have been added or it's the last sentence
        if ((index + 1) % 4 === 0 || index === sentences.length - 1) {
          // Capitalize the first letter of the paragraph
          paragraph = paragraph.charAt(0).toUpperCase() + paragraph.slice(1).trim();
          paragraphArray.push(paragraph);
          paragraph = ''; // Reset paragraph
        }
      }
    });
  
    return paragraphArray.map((para, idx) => <p key={idx}>{para}</p>); // Return as JSX <p> tags
  }

  return (
    <>
      <CardContainer>
        {/* Historical Summary */}
        <Card color={background} column="span 12" row="span 8" onClick={() => openModal(0)}>
          {/* <Title>Summary</Title> */}
          <ContentContainer>
              {cardData.historicalSummary.map((item, index) => (
                item.Value.toLowerCase() != 'nan' &&
                <div key={index}>
                  <strong style={{fontWeight: 'bold'}}> {item.Variable}</strong>: {splitTextIntoParagraphs(item.Value)}
                  <br />
                  <br />
                </div>
                
              ))}
            </ContentContainer>
        </Card>

        {/* Government */}
        <Card color={background}  column="span 6" row="span 4" onClick={() => openModal(1)}>
        {/* <Title>Government</Title> */}
          <ContentContainer>
              {cardData.government.map((gov, index) => (
                gov.Value.toLowerCase() != 'nan' &&
                <div key={index}>
                  {gov?.Variable ? (
                    <>
                      <strong style={{fontWeight: 'bold'}}>{gov.Variable}</strong>: {gov.Value}
                    </>
                  ) : (
                    gov.Value
                  )}
                  <br />
                  <br />
                </div>
              ))}
            </ContentContainer>
        </Card>
        <Card color={background}  column="span 6" row="span 4" onClick={() => openModal(7)}>
          {/* <Title>Citizenship</Title> */}
          <ContentContainer>
              {cardData.citizenship.map((item, index) => (
                item.Value.toLowerCase() != 'nan' &&
                <div key={index}>
                  <strong style={{fontWeight: 'bold'}}>{item.Variable}</strong>: {item.Value}
                  <br />
                  <br />
                </div>
              ))}
            </ContentContainer>
        </Card>

        <Card color={background}  column="span 6" row="span 4" onClick={() => openModal(4)}>
        {/* <Title>Legal System</Title> */}
          <ContentContainer>{cardData.legalSystem}</ContentContainer>
        </Card>


        {/* International Organization Participation */}
        <Card color={background}  column="span 6" row="span 4" onClick={() => openModal(8)}>
        {/* <Title>International Organization Participation</Title> */}
          <ContentContainer>{cardData.internationalParticipation}</ContentContainer>
        </Card>
{/*         
        <Card color={background}  column="span 4" row="span 4" onClick={() => openModal(10)}>
        <Title>Resources</Title>
          <ContentContainer>No Data Available</ContentContainer>
        </Card> */}

        <Card color={background}  column="span 4" row="span 4" onClick={() => openModal(5)}>
        {/* <Title>Legislative</Title> */}
          <ContentContainer>
              {cardData.legislative.map((item, index) => (
                item.Value.toLowerCase() != 'nan' &&
                <div key={index}>
                  <strong style={{fontWeight: 'bold'}}>{item.Variable}</strong>: {splitTextIntoParagraphs(item.Value)}
                  <br />
                  <br />
                </div>
              ))}
            </ContentContainer>
        </Card>
        
        {/* Citizenship */}
        {/* <Card color={background}  column="span 4" row="span 2" onClick={() => openModal(11)}>
          <Title>Statistics</Title>
          <ContentContainer>No Data Available</ContentContainer>
        </Card> */}

        {/* Judicial */}
        <Card color={background}  column="span 4" row="span 4" onClick={() => openModal(9)}>
        {/* <Title>Judicial</Title> */}
          <ContentContainer>
              {cardData.judicial.map((item, index) => (
                item.Value.toLowerCase() != 'nan' &&
                <div key={index}>
                  <strong style={{fontWeight: 'bold'}}>{item.Variable}</strong>: {item.Value}
                  <br />
                  <br />
                </div>
              ))}
            </ContentContainer>
        </Card>
        <Card color={background}  column="span 4" row="span 4" onClick={() => openModal(9)}>
        {/* <Title>Terrorist Group</Title> */}
          <ContentContainer>
              {cardData.terroristGroup.map((item, index) => (
                item.Value.toLowerCase() != 'nan' &&
                <div key={index}>
                  <strong style={{fontWeight: 'bold'}}>{item.Variable}</strong>: {item.Value}
                  <br />
                  <br />
                </div>
              ))}
            </ContentContainer>
        </Card>
        
        {/* Military */}
        <Card color={background}  column="span 12" row="span 4" onClick={() => openModal(3)}>
        {/* <Title>Military</Title> */}
          <ContentContainer>
            {cardData.military.length > 0 ? (
              [
                'Military and Security Forces',
                'Military and Security Forces Note',
                'Military - Note',
                'Military Equipment Inventories and Acquisitions',
                'Military and Security Service Personnel Strengths',
                'Military Deployments',
                'Military Service Age and Obligation',
              ].map((label, index) => {
                const mil = cardData.military.find(item => item.Variable === label);
                const value = mil ? mil.Value : 'Not Available';
          
                const paragraphs = splitTextIntoParagraphs(value);
          
                return (
                  value.toLowerCase() != 'nan' ?
                  <div key={index}>
                    <strong style={{ fontWeight: 'bold' }}>{label}</strong>:
                    {splitTextIntoParagraphs(value)}
                    <br />
                  </div> : ''
                );
              })
            ) : (
              'No data available'
            )}
          </ContentContainer>
        </Card>


      </CardContainer>

          </>
  );
};

export default FlipCardComponent;
