// src/components/charts/PieChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const chartData = {
    labels: data.map(chart => chart.date),
    datasets: [
      {
        label: 'Distribution',
        data: data.map(chart => chart.close), // Example data
        backgroundColor: ['#FF5733', '#33FF57', '#3357FF', '#F1C40F'],
        borderColor: '#ffffff',
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={chartData} />;
};

export default PieChart;
