import React from 'react';
import { SignIn } from '@clerk/clerk-react';

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000, // Ensure it appears above other content
};

const modalContentStyle = {
  background: 'white',
//   padding: '20px',
  borderRadius: '8px',
  width: '100%',
  maxWidth: '400px', // Adjust the size as needed
  position: 'relative',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '16px',
  cursor: 'pointer',
};

const SignInModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle} onClick={onClose}>
      <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <SignIn />
        <button style={closeButtonStyle} onClick={onClose}>X</button>
      </div>
    </div>
  );
};

export default SignInModal;
