import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import Header from "../../pages/Header/index"; // Import the Header component

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  buttonBackground: "#d97c6e",
  buttonHoverBackground: "#b65e4d",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  buttonBackground: "#d97c6e",
  buttonHoverBackground: "#b65e4d",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
`;

const Main = styled.div`
  background-color: ${({ theme }) => theme.background};
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const ProfileContainer = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const ProfileImage = styled.img`
  width: 100px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
  background-color: ${({ theme }) => theme.cardBackground}; /* Fallback background color */
`;

const DefaultProfileImage = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.cardBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color};
  font-size: 0.9em;
  text-align: center;
  line-height: 1.2;
`;

const ProfileDetails = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const ProfileRow = styled.div`
  display: flex;
  align-items: right;
  margin-bottom: 5px;
`;

const ProfileText = styled.p`
  color: ${(props) => (props.positive ? 'green' : props.negative ? 'red' : (props.theme.color))};
  margin-right: 5px;
`;

const NewsSection = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
`;

const NewsCard = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-size: 1.2em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color};
`;

const PublishedDate = styled.p`
  font-size: 0.9em;
  margin-bottom: 10px;
  color: gray;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.color};
  margin-bottom: 10px;
`;

const ReadMore = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const ReadLess = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const Heading = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.color};
`;

const NewsSectionStock = () => {
  const [articlesGroupedBySymbol, setArticlesGroupedBySymbol] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [profiles, setProfiles] = useState({});
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  const sanitizeHTML = (html) => {
    return {
      __html: html.replace(/<\/?[^>]+(>|$)/g, '')
    };
  };

  useEffect(() => {
    const fetchArticlesAndProfiles = async () => {
      try {
        setLoading(true);
        
        // Fetch news articles from multiple pages
        const pageCount = 5;
        const newsRequests = [];
        for (let page = 0; page < pageCount; page++) {
          newsRequests.push(
            axios.get(`https://financialmodelingprep.com/api/v3/stock_news?page=${page}&apikey=11cd608ea5136b9fdb748e3a80c1724e`)
          );
        }
        
        const newsResponses = await Promise.all(newsRequests);
        const articles = newsResponses.flatMap(response => response);

        // Group articles by symbol
        const groupedArticles = articles.reduce((acc, article) => {
          const symbol = article.symbol || "UNKNOWN";
          if (!acc[symbol]) {
            acc[symbol] = [];
          }
          acc[symbol].push(article);
          return acc;
        }, {});

        setArticlesGroupedBySymbol(groupedArticles);

        // Fetch profiles for each symbol
        const symbols = Object.keys(groupedArticles);
        const profilesResponse = await Promise.all(symbols.map(symbol =>
          axios.get(`https://financialmodelingprep.com/api/v3/profile/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`)
        ));

        const profilesData = profilesResponse.reduce((acc, profileResponse, index) => {
          acc[symbols[index]] = profileResponse[0];
          return acc;
        }, {});

        setProfiles(profilesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchArticlesAndProfiles();
  }, []);

  const handleCardClick = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };

  const ProfileImageWrapper = ({ src, alt, companyName }) => {
    const [imgSrc, setImgSrc] = useState(src);
    const handleError = () => {
      setImgSrc(null); // Clear the image source
    };

    return imgSrc ? (
      <ProfileImage src={imgSrc} alt={alt} onError={handleError} />
    ) : (
      <DefaultProfileImage>{companyName || 'No Image'}</DefaultProfileImage>
    );
  };

  if (loading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return <Container>Error: {error.message}</Container>;
  }

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Main>
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <Container>
          <ContentWrapper>
            {/* <Heading>Stock News</Heading> */}
            {Object.keys(articlesGroupedBySymbol).map((symbol) => (
              <CardWrapper key={symbol}>
                {/* Company Profile Section */}
                <ProfileContainer>
                  {profiles[symbol] && (
                    <>
                      <ProfileImageWrapper
                        src={profiles[symbol].image}
                        alt={profiles[symbol].companyName}
                        companyName={profiles[symbol].companyName}
                      />
                      <ProfileDetails>
                        <ProfileRow><ProfileText><strong>Symbol:</strong> {profiles[symbol].symbol}</ProfileText></ProfileRow>
                        <ProfileRow><ProfileText><strong>Company Name:</strong> {profiles[symbol].companyName}</ProfileText></ProfileRow>
                        <ProfileRow><ProfileText><strong>Price:</strong> ${profiles[symbol].price}</ProfileText></ProfileRow>
                        <ProfileRow><strong>Change:</strong> <ProfileText positive={profiles[symbol].changes > 0}>{profiles[symbol].changes}%</ProfileText></ProfileRow>
                        
                      </ProfileDetails>
                    </>
                  )}
                </ProfileContainer>
                
                {/* News Section */}
                <NewsSection>
                  {articlesGroupedBySymbol[symbol].map((article, index) => (
                    <NewsCard key={article.url} onClick={() => handleCardClick(index)}>
                      <Title>{article.title}</Title>
                      <PublishedDate>{new Date(article.publishedDate).toLocaleDateString()}</PublishedDate>
                      <Text dangerouslySetInnerHTML={sanitizeHTML(article.text)} />
                      {expandedCardIndex === index ? (
                        <ReadLess onClick={() => handleCardClick(index)}>Read Less</ReadLess>
                      ) : (
                        <ReadMore onClick={() => handleCardClick(index)}>Read More</ReadMore>
                      )}
                    </NewsCard>
                  ))}
                </NewsSection>
              </CardWrapper>
            ))}
          </ContentWrapper>
        </Container>
      </Main>
    </ThemeProvider>
  );
};

export default NewsSectionStock;
