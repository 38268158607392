import React, { useState, useEffect } from 'react';
import  { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useUser } from '@clerk/clerk-react'; // Using Clerk to fetch user details
import styled from 'styled-components';
import { FaBars, FaUser } from 'react-icons/fa'; 
import { FaGithub, FaGlobe, FaTwitter, FaPinterest } from 'react-icons/fa';
import Header from './Header';

// Define light and dark themes
const lightTheme = {
    background: "#ffffff",
    color: "#000000",
    cardBackground: "#f5f5f5",
    cardColor: "#000000",
    borderColor: "#000000",
  };
  
  const darkTheme = {
    background: "rgb(31 41 55)",
    color: "#ffffff",
    cardBackground: "rgb(55 65 81)",
    cardColor: "#ffffff",
    borderColor: "#ffffff",
  };
  
  // Global styles
  const GlobalStyle = createGlobalStyle`
    body {
      background-color: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.color};
      margin: 10%;
      height: 100%;
      transition: background-color 0.3s, color 0.3s;
    }
  `;
  
  // Main container
  const Container = styled.div`
    display: flex;
    // flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    // height: 100vh;
    overflow-y: auto;
    margin-top: 100px;
    // margin-left:5%;
  `;

const Sidebar = styled.div`
  width: 30%;
//   background-color: #ffffff;
  border-radius: 12px;
  padding: 0px 20px 120px 20px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding:40px;
  text-align: center;
  background-color: ${({ theme }) => theme.cardColor};
  color: ${({ theme }) => theme.color};
`;

const ProfileImgWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Light background for the default icon */
  position: relative;
`;

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DefaultIcon = styled(FaUser)`
  font-size: 2.5rem;
  color: #888;
`;
const ProfileName = styled.h3`
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
`;

const ProfileDesignation = styled.p`
  margin: 0;
  color: #888;
  font-size: 1.1rem;
`;

const ProgressSection = styled.div`
  margin-bottom: 40px;  
  padding:40px;
  text-align: center;
  background-color: ${({ theme }) => theme.cardColor};
  color: ${({ theme }) => theme.color};
`;

const ProgressTitle = styled.h4`
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
`;
const PortfolioSection = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.cardColor};
  color: ${({ theme }) => theme.color};
  padding:40px;
`;
const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #f0f0f0;
  height: 8px;
  border-radius: 5px;
  position: relative;
`;

const ProgressBar = styled.div`
  width: ${({ progress }) => progress}%;
  background-color: #f76c6c;
  height: 100%;
  border-radius: 5px;
  margin-top:30px
`;
const PercentageLabel = styled.div`
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  background-color: skyblue; /* Set the background color to sky blue */
  color: white; /* Set text color to white */
  padding: 5px 10px; /* Add some padding */
  border-radius: 3px; /* Optional: rounded corners */
  font-weight: bold;
  margin-top:-15px;
  
  white-space: nowrap; /* Prevent text from wrapping */
`;
const PortfolioTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const PortfolioLink = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const PortfolioIcon = styled.div`
  font-size: 1.5rem;
  color: #333;
  margin-right: 10px;
`;

const PortfolioInput = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #fff;
  &:focus {
    outline: none;
    border-color: #aaa;
  }
`;

// Main Content Styling
const MainContent = styled.div`
  flex-grow: 1;
  max-width: 900px;
  background-color: ${({ theme }) => theme.cardColor};
  color: ${({ theme }) => theme.background};
  padding: 30px;    
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  border-bottom: 2px solid #e0e0e0;
`;

const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#007bff' : '#888')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  border-bottom: ${({ active }) => (active ? '2px solid #007bff' : 'none')};
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem;
//   color: #fff;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
  background-color: ${({ cancel }) => (cancel ? '#ccc' : '#007bff')};
  color: ${({ cancel }) => (cancel ? '#333' : '#fff')};
`;

// Main Settings Component
const Settings = () => {
  const { isLoaded, user } = useUser(); // Fetch user data from Clerk
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  // State for profile data
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    designation: 'Lead Designer / Developer',
    joiningDate: '24 Nov, 2021', // Static for now
    skills: ['Javascript'], // Array of skills
    city: 'California',
    country: 'United States',
    zipCode: '90011',
    website: 'www.velzon.com',
    description:
      "",
    portfolioLinks: [
      { icon: 'github', name: 'GitHub', url: '@daveadame' },
      { icon: 'web', name: 'Website', url: 'www.velzon.com' },
      { icon: 'twitter', name: 'Twitter', url: '@dave_adame' },
      { icon: 'pinterest', name: 'Pinterest', url: 'Advance Dave' },
    ],
  });

  const [activeTab, setActiveTab] = useState('Personal Details'); // Tab management

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const calculateProgress = () => {
    let filledFields = 0;
    const totalFields = 10;
    if (profileData.firstName) filledFields++;
    if (profileData.lastName) filledFields++;
    if (profileData.emailAddress) filledFields++;
    if (profileData.phoneNumber) filledFields++;
    if (profileData.city) filledFields++;
    if (profileData.country) filledFields++;
    if (profileData.zipCode) filledFields++;
    if (profileData.website) filledFields++;
    if (profileData.skills.length > 0) filledFields++;
    if (profileData.description) filledFields++;
    return (filledFields / totalFields) * 100;
  };

  const profileData1 = {
    portfolioLinks: [
      { icon: <FaGithub />, name: 'Github', url: 'https://github.com/daveadame' },
      { icon: <FaGlobe />, name: 'Website', url: 'https://www.velzon.com' },
      { icon: <FaTwitter />, name: 'Twitter', url: 'https://twitter.com/dave_adame' },
      { icon: <FaPinterest />, name: 'Pinterest', url: 'https://pinterest.com/advance_dave' },
    ],
  };

  // Loading user data from Clerk when available
  useEffect(() => {
    if (isLoaded && user) {
      setProfileData({
        ...profileData,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        emailAddress: user.emailAddresses[0]?.emailAddress || '',
      });
    }
  }, [isLoaded, user]);

  if (!isLoaded) return <div>Loading...</div>;
  if (!user) return <div>No user found</div>;
  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
    <Container>
      {/* Sidebar */}
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
      <Sidebar>
        <ProfileSection>
        <ProfileImgWrapper>
            {user.profileImageUrl ? <ProfileImg src={user.profileImageUrl} alt="Profile" /> : <DefaultIcon />}
        </ProfileImgWrapper>
          <ProfileName>{`${profileData.firstName} ${profileData.lastName}`}</ProfileName>
          <ProfileDesignation>{profileData.designation}</ProfileDesignation>
        </ProfileSection>

        <ProgressSection>
          <ProgressTitle>Complete Your Profile</ProgressTitle>
          <ProgressBarContainer>
            <ProgressBar progress={calculateProgress()} />
            <PercentageLabel>{calculateProgress()}%</PercentageLabel>
          </ProgressBarContainer>
        </ProgressSection>

        <PortfolioSection>
    <PortfolioTitle>Portfolio</PortfolioTitle>
    {profileData1.portfolioLinks.map((link, index) => (
      <PortfolioLink key={index}>
        <PortfolioIcon>{link.icon}</PortfolioIcon>
        <PortfolioInput
          type="text"
          placeholder={link.url}  // Using the URL as the placeholder
          defaultValue={link.url}  // Setting the value as the portfolio link
        />
      </PortfolioLink>
    ))}
  </PortfolioSection>
      </Sidebar>

      {/* Main Content */}
      <MainContent>
        <Tabs>
          {['Personal Details', 'Change Password', 'Experience', 'Privacy Policy'].map((tab) => (
            <Tab key={tab} active={activeTab === tab} onClick={() => handleTabChange(tab)}>
              {tab}
            </Tab>
          ))}
        </Tabs>

        {/* Form Fields */}
        <FormGroup>
          <FormLabel>First Name</FormLabel>
          <FormInput
            type="text"
            name="firstName"
            value={profileData.firstName}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Last Name</FormLabel>
          <FormInput
            type="text"
            name="lastName"
            value={profileData.lastName}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Email Address</FormLabel>
          <FormInput
            type="email"
            name="emailAddress"
            value={profileData.emailAddress}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Phone Number</FormLabel>
          <FormInput
            type="tel"
            name="phoneNumber"
            value={profileData.phoneNumber}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Website</FormLabel>
          <FormInput
            type="url"
            name="website"
            value={profileData.website}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>City</FormLabel>
          <FormInput
            type="text"
            name="city"
            value={profileData.city}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Country</FormLabel>
          <FormInput
            type="text"
            name="country"
            value={profileData.country}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Zip Code</FormLabel>
          <FormInput
            type="text"
            name="zipCode"
            value={profileData.zipCode}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Description</FormLabel>
          <FormTextarea
            name="description"
            rows="4"
            value={profileData.description}
            onChange={handleInputChange}
          />
        </FormGroup>

        <ButtonGroup>
          <Button cancel>Cancel</Button>
          <Button>Update</Button>
        </ButtonGroup>
      </MainContent>
    </Container>
    </ThemeProvider>
  );
};

export default Settings;
