import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle, useTheme } from 'styled-components';
import { supabase } from "../supabaseClient";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
};

const darkTheme = {
  background: '#222122',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
};

const TableHeader = styled.th`
  color: #fff;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.primary};
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 150px;
`;

const MainLayout = styled.div`
  min-width: 100%;
  flex-grow: 1;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  top: 50px;
  height: 100vh;
  border-right: 2px solid ${({ theme }) => theme.color};
`;

const SidebarItem = styled.div`
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const MainContent = styled.div`
  margin-left: 270px;
  padding: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
margin-right:10px;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const templateData = [
  { 'Portfolio Name': '', 'Model': '', 'Benchmark': '' }
];

const generateTemplate = (type) => {
  const ws = XLSX.utils.json_to_sheet(templateData, { header: ['Portfolio Name', 'Model', 'Benchmark'] });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Template');

  if (type === 'xlsx') {
    XLSX.writeFile(wb, 'portfolio_template.xlsx');
  } else if (type === 'csv') {
    const csvData = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'portfolio_template.csv');
    link.click();
  }
};

const AddPortfolio = () => {
  const [portfolioName, setPortfolioName] = useState('');
  const [model, setModel] = useState('');
  const [benchmark, setBenchmark] = useState('');
  const [otherModel, setOtherModel] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!portfolioName) {
      toast.error(`Portfolio name is empty`);
      return;
    }
    const modelValue = model === 'Other' ? otherModel : model;

    try {
      const { data, error } = await supabase
        .from('PortfolioComposition')
        .insert([{ portfolio_name: portfolioName, model: modelValue, benchmark }]);

      if (error) {
        throw error;
      }
      toast.success('Portfolio added successfully!');
    } catch (error) {
      toast.error(`Error inserting data: ${error.message}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '50%' }}>
      <FormGroup>
        <Label>Portfolio Name</Label>
        <Input
          type="text"
          value={portfolioName}
          onChange={(e) => setPortfolioName(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label>Model</Label>
        <Select value={model} onChange={(e) => setModel(e.target.value)}>
          <option value="">Select Model</option>
          <option value="Growth">Growth</option>
          <option value="Moderate">Moderate</option>
          <option value="Market">Market</option>
          <option value="Conservative">Conservative</option>
          <option value="Other">Other</option>
        </Select>
        {model === 'Other' && (
          <Input
            type="text"
            placeholder="Specify Other Model"
            value={otherModel}
            onChange={(e) => setOtherModel(e.target.value)}
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label>Benchmark</Label>
        <Input
          type="text"
          value={benchmark}
          onChange={(e) => setBenchmark(e.target.value)}
        />
      </FormGroup>

      <Button type="submit">Add Portfolio</Button>
    </form>
  );
};

const ViewPortfolio = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const fetchPortfolios = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.from('PortfolioComposition').select('*');

      if (error) {
        throw error;
      }
      setPortfolios(data);
      toast.success('Portfolios fetched successfully!');
    } catch (error) {
      toast.error(`Error fetching portfolios: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPortfolios();
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
      // Handle XLSX file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const formattedData = jsonData.map(row => ({
          portfolio_name: row['Portfolio Name'],
          model: row['Model'],
          benchmark: row['Benchmark'],
        }));

        supabase
          .from('PortfolioComposition')
          .insert(formattedData)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }
            toast.success('XLSX data inserted successfully!');
            fetchPortfolios(); // Refresh the list
          })
          .catch((error) => {
            toast.error(`Error inserting XLSX data: ${error.message}`);
          })
          .finally(() => {
            setLoading(false);
          });
      };
      reader.readAsArrayBuffer(file);
    } else if (file.type === 'text/csv') {
      // Handle CSV file
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const data = results.data;

          const formattedData = data.map(row => ({
            portfolio_name: row['Portfolio Name'],
            model: row['Model'],
            benchmark: row['Benchmark'],
          }));

          supabase
            .from('PortfolioComposition')
            .insert(formattedData)
            .then(({ data, error }) => {
              if (error) {
                throw error;
              }
              toast.success('CSV data inserted successfully!');
              fetchPortfolios(); // Refresh the list
            })
            .catch((error) => {
              toast.error(`Error inserting CSV data: ${error.message}`);
            })
            .finally(() => {
              setLoading(false);
            });
        },
      });
    } else {
      toast.error('Invalid file format. Please upload a CSV or XLSX file.');
      setLoading(false);
    }
  };

  const downloadData = () => {
    const ws = XLSX.utils.json_to_sheet(portfolios, { header: ['Portfolio Name', 'Model', 'Benchmark'] });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, 'portfolio_data.xlsx');
  };

  return (
    <div>
      
      {loading && <p>Loading...</p>}
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead>
          <tr style={{ backgroundColor: theme.primary, color: '#fff', fontWeight: 'bold' }}>
            <TableHeader>Index</TableHeader>
            <TableHeader>Portfolio Name</TableHeader>
            <TableHeader>Model</TableHeader>
            <TableHeader>Benchmark</TableHeader>
          </tr>
        </thead>
        <tbody>
          {portfolios.map((portfolio, index) => (
            <tr
              key={portfolio.id}
              style={{
                backgroundColor: theme.background,
                color: theme.color,
              }}
            >
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {index + 1}
              </td>
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.portfolio_name}
              </td>
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.model}
              </td>
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.benchmark}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br/>
      <input
        type="file"
        accept=".csv, .xlsx, .xls"
        onChange={handleFileUpload}
        style={{ marginBottom: '20px' }}
      /><br/>
      <Button onClick={() => generateTemplate('csv')} style={{ marginBottom: '20px' }}>
        Download CSV Template
      </Button>
      <Button onClick={() => generateTemplate('xlsx')} style={{ marginBottom: '20px' }}>
        Download Excel Template
      </Button>
      
      <Button onClick={downloadData} style={{ marginBottom: '20px' }}>
        Download Data
      </Button>
    </div>
  );
};

const Clients = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme ? savedTheme.replace(/^"|"$/g, '') : 'light';
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme;
    }
  });
  const [selectedContent, setSelectedContent] = useState('view');

  const renderContent = () => {
    if (selectedContent === 'add') return <AddPortfolio />;
    if (selectedContent === 'view') return <ViewPortfolio />;
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Container>
        <MainLayout>
          <Sidebar>
            {/* <SidebarItem onClick={() => setSelectedContent('add')}>
              Add Portfolio
            </SidebarItem> */}
            <SidebarItem onClick={() => setSelectedContent('view')}>
              View Portfolio
            </SidebarItem>
          </Sidebar>
          <MainContent>{renderContent()}</MainContent>
        </MainLayout>
      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default Clients;
