import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ColumnChart = ({ data }) => {
  const chartData = {
    labels: data.map(chart => chart.date),
    datasets: [
      {
        label: 'Volume',
        data: data.map(chart => chart.volume),
        backgroundColor: 'rgba(75, 192, 192, 0.5)', // Semi-transparent bar color
        borderColor: 'rgba(75, 192, 192, 1)', // Bar border color
        borderWidth: 1, // Border thickness
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: '#000',
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `Volume: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          color: '#ffffff',
          autoSkip: true,
          maxTicksLimit: 10,
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true, // Ensure the y-axis starts at zero
        ticks: {
          color: '#000',
          padding: 10,
          callback: function(value) {
            return `${value}`; // Format Y-axis labels
          },
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
          borderColor: 'rgba(255, 255, 255, 0.6)', // Ensure grid lines are visible
          borderDash: [5, 5], // Optional: dashed grid lines
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default ColumnChart;
