import React , {useEffect} from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";
import CookieConsent from "./pages/Theme/CookieConsent";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { loginSuccess } from "./slices/auth/login/reducer";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = () => {  const dispatch = useDispatch();
  useEffect(() => {
  const token = localStorage.getItem('authToken');
  if(token){
    dispatch(loginSuccess({
      token,
    }));
  }else{
    <Navigate to={{ pathname: "/login"}} />

  }
})
  return (
    <React.Fragment>
      
      <Route />
      <CookieConsent />
    </React.Fragment>
  );
}

export default App;
