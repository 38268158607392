import React, { useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Header from '../Header';
import {
  calculateRSI,
  calculateSMA,
  calculateEMA,
  calculateWMA,
  calculateMACD,
} from '../../Components/Common/indicators';
import { FaSearch ,FaEdit} from 'react-icons/fa'; // Import a search icon from react-icons


const sortByDate = (data) => {
  return data.slice(0,500).sort((a, b) => new Date(a.date) - new Date(b.date));
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666'
};

const darkTheme = {
  background: '#222122',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#ccc'
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top:50px;
`;

const MainLayout = styled.div`
  display: flex;
  flex-grow: 1;
`;

const SearchBarContainer = styled.div`
 
position: relative;
  width: 80%;
  margin-top:60px;
  margin-bottom:20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 40px 10px 15px; /* Added padding to accommodate the icon */
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  transform: translateY(-50%);
  color: ${({ theme }) => theme.primary};
`;

const Dropdown = styled.div`
  position: 20px;
  top: 110px; /* Adjust based on the SearchBar height */
  left: 0;
  width: 80%;
  max-height: 200px; /* Set the maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  z-index: 1000; /* Ensure dropdown appears above other content */
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
  }
`;

const IndicatorSelectContainer = styled.div`
  position: relative;
  width: 30%; // Adjust width to fit in row
  margin-top: 60px;
  margin-bottom: 20px;
`;
const IndicatorSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  font-size: 16px;
`;

const TradingIndicatorChartWithSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? darkTheme : lightTheme;
  });

  const [selectedIndicator, setSelectedIndicator] = useState('');
  const [indicatorParams, setIndicatorParams] = useState({ period: 14, shortPeriod: 12, longPeriod: 26, signalPeriod: 9 });

  // Fetch search results based on query
  const fetchSearchResults = async (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    try {
      const response = await axios.get(
        `https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`
      );
      setSearchResults(response); // Correctly set search results
      setError(null);
    } catch (error) {
      console.error('Error fetching search results', error);
      setError('Failed to fetch search results');
    }
  };

  // Fetch chart data based on selected symbol
  const fetchChartData = async (symbol) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`
      );
      const data = response.historical.map((item) => ({
        date: item.date,
        close: item.adjClose,
        volume: item.volume,
        open: item.open,
        high: item.high,
        low: item.low,
      }));
      setChartData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching chart data', error);
      setError('Failed to fetch chart data');
    }
    setLoading(false);
  };

  // Create price series data for chart
  const sortedData = sortByDate(chartData);
  const priceSeries = [
    {
      name: 'Adjusted Close Price',
      data: sortedData.map((chart) => ({
        x: chart.date,
        y: chart.close,
      })),
    },
  ];

  // Function to calculate indicator series data based on selected indicator and its parameters
  const getIndicatorSeries = () => {
    let indicatorValues = [];
    switch (selectedIndicator) {
      case 'RSI':
        indicatorValues = calculateRSI(sortedData, indicatorParams.period);
        break;
      case 'SMA':
        indicatorValues = calculateSMA(sortedData, indicatorParams.period);
        break;
      case 'EMA':
        indicatorValues = calculateEMA(sortedData, indicatorParams.period);
        break;
      case 'WMA':
        indicatorValues = calculateWMA(sortedData, indicatorParams.period);
        break;
      case 'MACD':
        indicatorValues = calculateMACD(
          sortedData,
          indicatorParams.shortPeriod,
          indicatorParams.longPeriod,
          indicatorParams.signalPeriod
        );
        break;
      default:
        return [];
    }

    return {
      name: selectedIndicator,
      data: sortedData.map((chart, index) => ({
        x: chart.date,
        y: selectedIndicator === 'MACD' ? indicatorValues[index]?.macd : indicatorValues[index],
      })),
    };
  };

  // Chart options for the line chart (price data)
//   const priceOptions = {
//     chart: {
//       id: 'priceChart',
//       type: 'line',
//       height: 350,
//       zoom: {
//         enabled: true,
//       },
//     },
//     colors: ['#f00', '#00f'], // Adjusted to include the indicator color
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: 'smooth',
//       width: [5, 2], // The first number is for price series, second for indicator series
//     },
//     xaxis: {
//       type: 'datetime',
//     },
//     yaxis: {
//       labels: {
//         formatter: (value) => `$${value}`,
//       },
//     },
//     tooltip: {
//       y: {
//         formatter: (value) => `$${value}`,
//       },
//     },
//   };

const volumeSeries = [
    {
      name: 'Volume',
      data: sortedData.map((chart) => ({
        x: chart.date,
        y: chart.volume,
      })),
    },
  ];

  // Chart options for the line chart (price data)
  const priceOptions = {
    chart: {
      id: 'priceChart',
      type: 'line',
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    colors: ['#f00', '#00f'], // Adjusted to include the indicator color
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: [5, 2], // The first number is for price series, second for indicator series
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value}`,
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value}`,
      },
    },
  };

  const volumeOptions = {
    chart: {
      id: 'volumeChart',
      type: 'bar',
      height: 150,
      toolbar: {
        show: false,
      },
    },
    colors: ['#00BFFF'], // Color for the volume bars
    xaxis: {
      type: 'datetime',
      labels: {
        rotate: -45,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => value.toLocaleString(), // Format volume number
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toLocaleString(), // Format volume tooltip
      },
    },
  };

  

  // Render search results dropdown
  const renderSearchResults = () => (
    searchResults.length > 0 && (
        <Dropdown>
          {searchResults.map(result => (
            <DropdownItem key={result.symbol} onClick={() => {
                        setSelectedSymbol(result.symbol);
            fetchChartData(result.symbol);
            setSearchQuery(''); // Clear the search query
            setSearchResults([]); // Clear the search results
            }}>
              {result.symbol} - {result.name}
            </DropdownItem>
          ))}
        </Dropdown>
      )
  );

  // Dynamic input fields for indicator parameters
  const renderIndicatorParams = () => {
    switch (selectedIndicator) {
      case 'RSI':
      case 'SMA':
      case 'EMA':
      case 'WMA':
        return (
          <div>
            <label>Period:</label>
            <input
              type="number"
              value={indicatorParams.period}
              onChange={(e) => setIndicatorParams({ ...indicatorParams, period: parseInt(e.target.value) })}
              style={{ padding: '5px', marginLeft: '10px' }}
            />
          </div>
        );
      case 'MACD':
        return (
          <div>
            <label>Short Period:</label>
            <input
              type="number"
              value={indicatorParams.shortPeriod}
              onChange={(e) => setIndicatorParams({ ...indicatorParams, shortPeriod: parseInt(e.target.value) })}
              style={{ padding: '5px', marginLeft: '10px' }}
            />
            <label style={{ marginLeft: '20px' }}>Long Period:</label>
            <input
              type="number"
              value={indicatorParams.longPeriod}
              onChange={(e) => setIndicatorParams({ ...indicatorParams, longPeriod: parseInt(e.target.value) })}
              style={{ padding: '5px', marginLeft: '10px' }}
            />
            <label style={{ marginLeft: '20px' }}>Signal Period:</label>
            <input
              type="number"
              value={indicatorParams.signalPeriod}
              onChange={(e) => setIndicatorParams({ ...indicatorParams, signalPeriod: parseInt(e.target.value) })}
              style={{ padding: '5px', marginLeft: '10px' }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header onToggleTheme={() => setTheme(theme === lightTheme ? darkTheme : lightTheme)} />
        <MainLayout>
          <div style={{ flexGrow: 1, padding: '20px' }}>
            {/* <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                fetchSearchResults(e.target.value); // Fetch results based on current query
              }}
              placeholder="Search for a stock..."
              style={{ padding: '10px', width: '300px' }}
            /> */}
            <SearchBarContainer>
            <SearchInput
            type="text"
            placeholder="Search for a symbol (e.g., AAPL)"
            value={searchQuery}
            onChange={(e) => {
                setSearchQuery(e.target.value);
                fetchSearchResults(e.target.value); }}
            />
            <SearchIcon />
        </SearchBarContainer>
            {searchResults.length > 0 && renderSearchResults()}
            
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <IndicatorSelectContainer>
            <IndicatorSelect
              value={selectedIndicator}
              onChange={(e) => setSelectedIndicator(e.target.value)}
            >
              <option value="">Select Indicator</option>
              <option value="RSI">RSI</option>
              <option value="SMA">SMA</option>
              <option value="EMA">EMA</option>
              <option value="WMA">WMA</option>
              <option value="MACD">MACD</option>
            </IndicatorSelect>
            {/* {renderIndicatorParams()} */}
          </IndicatorSelectContainer>
            {renderIndicatorParams()}
            {loading ? <p>Loading...</p> : (
              <ReactApexChart
                options={priceOptions}
                series={[...priceSeries, getIndicatorSeries()]}
                type="line"
                height={350}
              />
            )}
            
          </div>
        </MainLayout>
        {/* <div style={{ flex: 1 }}>
            <ReactApexChart options={volumeOptions} series={volumeSeries} type="bar" height={150} />
          </div> */}
      </Container>
    </ThemeProvider>
  );
};

export default TradingIndicatorChartWithSearch;
