import React from "react";
import { Container } from "reactstrap";

const Dashboard = () => {
  document.title = "Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          Dashboard
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
