import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header/index'; // Adjust path based on your project structure
import headerImage from '../../assets/images/vegaveta/header-image.jpg'; // Adjust the path based on your project structure
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import Footer from '../../Components/Common/Footer';

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  buttonBackground: "#4CAF50",
  buttonColor: "#ffffff",
  discountColor: "#FF5733",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
  buttonBackground: "#AC544C",
  buttonColor: "#ffffff",
  discountColor: "#FF5733",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  padding-top: 50px;
  text-align: left;
  margin-top: 70px;
`;

const PricingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const PricingCard = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
`;

const PricingHeader = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 1.5em;
  font-family: 'Lora', serif;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.cardColor};
`;

const Discount = styled.span`
  color: ${({ theme }) => theme.discountColor};
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
`;

const Price = styled.p`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonColor};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    opacity: 0.8;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
`;

const FeatureItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
  }
`;

const Pricing = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  // Pricing options data
  const pricingOptions = [
    {
      title: 'VegaVeta Anchor',
      priceMonthly: '$550 per month',
      priceYearly: '$5500 per year (save 20%)',
      features: [
        'Stock Prices — North America',
        'Fundamental Data — North America',
        'Commodity Data — US commodities',
        'Forex Data involving USD',
        'Cryptocurrency Data',
        'Weekly Chartbook',
        'Customizable Dashboard',
        'Chart Creation',
        'Portfolio Monitoring',
        'Portfolio Analysis (updated weekly)',
        'Index Prices — North America',
        'Economic and Socioeconomic Data',
        'Report Builder',
        'Real-time News',
        'ESG Data',
        'Sector and Industry Performance',
        'Watchlist',
        'Email/Ticketing Customer Support'
      ]
    },
    {
      title: 'VegaVeta Ascent',
      priceMonthly: '$750 per month',
      priceYearly: '$7500 per year (save 20%)',
      features: [
        'Everything in VegaVeta Anchor plus the following:',
        'Global Stock Prices*',
        'Global Fundamental Data*',
        'Global Political Data',
        'Global Economic and Socioeconomic Data',
        'Global/Region Analysis',
        'Global Index Analysis',
        'Global Sector and Industry In-depth Analysis',
        'VegaVeta LLM Reporting',
        'VegaVeta Portolio Optimizer',
        'Advanced Portfolio Analysis',
        'Weekly Chartbook (personalized with your logo)',
        'Organization Creation',
        'Client Portal',
        'Organization/Client Chat',
        'Auto Report Email',
        'Mutual Funds*',
        'ETF*',
        'Email/Ticketing Customer Support Priority'
      ]
    },
    {
      title: 'VegaVeta Apex',
      priceMonthly: 'Coming Soon',
      priceYearly: '',
      features: [
        'Everything in VegaVeta Ascent plus the following:',
        'VegaVeta QuinBridge Dynamic Model',
        'VegaVeta ML Securities Recommendation',
        'VegaVeta Watchdog',
        'VetaVeta AutoNav',
        'VegaVeta ML Forecasting',
        'VegaVeta Lab',
        'Dedicated Customer Success Manager'
      ]
    },
    {
      title: 'VegaVeta Enterprise',
      priceMonthly: 'Contact Us',
      priceYearly: '',
      features: [
        'Customizable Solutions',
        'Enhanced Security',
        'Dedicated Support',
        'Scalability',
        'Collaboration Tools'
      ]
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header />
        <PricingContainer>
          {pricingOptions.map((option, index) => (
            <PricingCard key={index}>
              <PricingHeader>
                <Title>{option.title}</Title>
                <hr />
              </PricingHeader>
              {option.priceYearly && <Discount>{option.priceYearly}</Discount>}
              <Price>{option.priceMonthly}</Price>
              <Button>Configure Your Server</Button>
              <FeaturesList>
                {option.features.map((feature, idx) => (
                  <FeatureItem key={idx}>
                    <i className="fas fa-check"></i> {feature}
                  </FeatureItem>
                ))}
              </FeaturesList>
            </PricingCard>
          ))}
        </PricingContainer>
      </Container>        <Footer />

    </ThemeProvider>
  );
};

export default Pricing;
