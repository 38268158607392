import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Header from '../../pages/Header';
import Footer from '../../Components/Common/Footer';
import LineChart from './LineChart';
import BarChart from './BarChart';
import NewsSection from './NewsSection';
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f1f1f1',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666'
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#ccc',
  cardBackground: "rgb(55 65 81)",
};

const Container = styled.div`
  padding-top: 50px;
  margin-top: 100px;
  margin-left: 10%;
  margin-right: 10%;
  min-height:100vh;
`;

const ContentWrapper = styled.div`
  max-width: 100%;  
  margin: 0 auto;
  padding: 50px;
  display: flex;
  flex-direction: row;

`;

const SearchBar = styled.input`
  width: 50%;
  padding: 10px;
  font-size: 1.2em;
  margin-bottom: 0px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const Suggestions = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.primary};
  max-height: 150px;
  overflow-y: auto;
  width: 50%;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: white;
  }
`;

const CompanyProfile = styled.div`
  display: flex;
  align-items: flex-start;
//   width: 60%;
  padding:10px;
  color: ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.background === '#f1f1f1' ? '#fff' : 'rgb(55 65 81)'};
`;

const CompanyImage = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 20px;
  background-color: ${({ theme }) => 
  theme.background === '#f1f1f1' 
    ? '#fff' 
    : 'rgb(55 65 81)'
}
`;

const CompanyImage1= styled.img`
  width: 100px;
  height: 100px;
  margin-right: 20px;
  background-color: ${({ theme }) => 
  theme.background === '#f1f1f1' 
    ? 'gray' 
    : 'rgb(55 65 81)'
}`;

const CompanyDetails = styled.div`
  h2 {
    margin: 0;
    color: ${({ theme }) => theme.color};
  }
  h3 {
    margin: 5px 0;
    font-weight: bold;
    color: ${({ theme }) => theme.primary};
  }
  .highlighted {
    font-weight: bold;
    font-size: 1.1em;
    color: ${({ theme }) => theme.primary};
  }
    
`;

const ChartContainer = styled.div`
//   width: 40%;
  padding: 20px;
  background-color: ${({ theme }) => theme.background === '#f1f1f1' ? '#fff' : 'rgb(55 65 81)'};
  border-radius: 8px;
//   max-height:500px;
//   margin-left:10px;
`;

const ESGData = styled.div`
  margin-top: 20px;
  // font-size: 0.9em;
  // color: ${({ theme }) => theme.captionColor};
`;

const BenchmarkCard = styled.div`
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
  color:  ${({ theme }) => theme.color};
  background-color: ${({ theme }) => theme.background === '#f1f1f1' ? '#fff' : 'rgb(55 65 81)'};
`;

const Card = styled.div`
  padding: 20px;
  border-radius: 8px;
  max-height:500px;
  overflow-y:auto;
  background-color: ${({ theme }) => theme.background === '#f1f1f1' ? '#fff' : 'rgb(55 65 81)'};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
//   padding:50px;
`;
const NewsContainer = styled.div`
    margin-top: 20px;    
    
`;
const CompanySummary = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [esgData, setESGData] = useState(null);
  const [esgBenchmark, setESGBenchmark] = useState(null);
  const [theme, setTheme] = useState(lightTheme);

  const [cashFlowData, setCashFlowData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [enterpriseValueData, setEnterpriseValueData] = useState([]);
  const [enterpriseValueAltData, setEnterpriseValueAltData] = useState([]);
  const [symbol1, setSymbol1] = useState('AAPL');
  const [labels, setLabels] = useState([]);
  const [EsgRiskRating, setEsgRiskRating] = useState([]);  

  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const fetchCompanyData = async (symbol) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/company/profile/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSelectedCompany(response);
      fetchChartData(symbol);
      fetchESGData(symbol);
      fetchESGBenchmarkData(response.profile.industry);
      fetchCashFlowData(symbol);
    fetchRevenueData(symbol);
    fetchEnterpriseValueData(symbol);
    fetchEnterpriseValueAltData(symbol);
    fetchESGRiskRating(symbol);
    } catch (error) {
      console.error('Error fetching company data', error);
    }
  };

  const fetchChartData = async (symbol) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=2019-01-01`);
      const data = response.historical.slice(0,90).map(item => ({
        date: item.date,
        close: item.adjClose,
      }));
      setLabels(data.map(item => item.date).reverse()); // Extract labels (dates)
      setChartData(data.map(item => item.close).reverse());
      
    } catch (error) {
      console.error('Error fetching chart data', error);
    }
  };

  const fetchESGData = async (symbol) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v4/esg-environmental-social-governance-data?symbol=${symbol}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      const latestESG = response[0];
      setESGData({
        environmentalScore: latestESG.environmentalScore,
        socialScore: latestESG.socialScore,
        governanceScore: latestESG.governanceScore,
        ESGScore: latestESG.ESGScore
      });
    } catch (error) {
      console.error('Error fetching ESG data', error);
    }
  };

  const fetchESGBenchmarkData = async (industry) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v4/esg-environmental-social-governance-sector-benchmark?year=2024&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      const benchmarkData = response.find(item => item.sector === industry);
      if (benchmarkData) {
        setESGBenchmark({
          environmentalScore: benchmarkData.environmentalScore,
          socialScore: benchmarkData.socialScore,
          governanceScore: benchmarkData.governanceScore,
          ESGScore: benchmarkData.ESGScore
        });
      }
    } catch (error) {
      console.error('Error fetching ESG benchmark data', error);
    }
  };

  const fetchCashFlowData = async (symbol) => {
    const response = await axios.get(`https://financialmodelingprep.com/api/v3/cash-flow-statement/${symbol}?period=quarter&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
    const data = response.slice(0, 20).map((item) => ({
      date: item.date,
      value: item.freeCashFlow,
    }));
    setCashFlowData(data);
  };

  const fetchRevenueData = async (symbol) => {
    const response = await axios.get(`https://financialmodelingprep.com/api/v3/income-statement/${symbol}?period=quarter&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
    const data = response.slice(0, 20).map((item) => ({
      date: item.date,
      value: item.revenue,
    }));
    setRevenueData(data);
  };

  const fetchEnterpriseValueData = async (symbol) => {
    const response = await axios.get(`https://financialmodelingprep.com/api/v3/key-metrics/${symbol}?period=quarter&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
    const data = response.slice(0, 20).map((item) => ({
      date: item.date,
      value: item.evToFreeCashFlow,
    }));
    setEnterpriseValueData(data);
  };

  const fetchEnterpriseValueAltData = async (symbol) => {
    const response = await axios.get(`https://financialmodelingprep.com/api/v3/enterprise-values/${symbol}/?period=quarter&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
    const data = response.slice(0, 20).map((item) => ({
      date: item.date,
      value: item.enterpriseValue,
    }));
    setEnterpriseValueAltData(data);
  };

  const fetchESGRiskRating= async (symbol) => {
    const response = await axios.get(`https://financialmodelingprep.com/api/v4/esg-environmental-social-governance-data-ratings?symbol=${symbol}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
    const data = response.reduce((max, current) => {
      return current.year > max.year ? current : max;
    }, response[0]);
    setEsgRiskRating(data);
  };

  useEffect(() => {
    if (symbol1) {
      fetchCompanyData(symbol1);
    }
  }, [symbol1]);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value) fetchSearchResults(value);
    else setSearchResults([]);
  };

  const handleSelectCompany = (company) => {
    
    setSelectedCompany(company.name);
    fetchCompanyData(company.symbol);
    setSymbol1(company.symbol);
    setSearchResults([]);
  };


  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company.companyName);
    setSymbol1(company.symbol);
    fetchCompanyData(company.symbol);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
          onCompanySelect={handleCompanySelect}
        />
      <Container>
        
        {/* <SearchBar
          type="text"
          placeholder="Search by company name or symbol..."
          value={query}
          onChange={handleSearchChange}
        />
        {searchResults.length > 0 && (
          <Suggestions>
            {searchResults.map((result) => (
              <SuggestionItem
                key={result.symbol}
                onClick={() => handleSelectCompany(result)}
              >
                {result.name} ({result.symbol})
              </SuggestionItem>
            ))}
          </Suggestions>
        )} */}
        {/* <ContentWrapper> */}
        <GridContainer>
        {selectedCompany && selectedCompany.profile && selectedCompany.profile.image && (
            <>
              <CompanyProfile>{
                symbol1 === 'AAPL' ?
                <CompanyImage1 src={selectedCompany.profile.image} alt={selectedCompany.profile.companyName} />:
                <CompanyImage src={selectedCompany.profile.image} alt={selectedCompany.profile.companyName} />}
                <CompanyDetails>
                  <h2>{selectedCompany.profile.companyName} ({selectedCompany.symbol})</h2>
                  <h3 className="highlighted">${selectedCompany.profile.price} | Market Cap: ${selectedCompany.profile.mktCap}</h3>
                  <p>{selectedCompany.profile.description}</p>
                  <p>CEO: {selectedCompany.profile.ceo}</p>
                  <p>Sector: {selectedCompany.profile.sector}</p>
                  <p>Industry: {selectedCompany.profile.industry}</p>
                  <p>Country: {selectedCompany.profile.country}</p>
                  {esgData && (
                    <ESGData>
                      <p>Environmental Score: {esgData.environmentalScore}</p>
                      <p>Social Score: {esgData.socialScore}</p>
                      <p>Governance Score: {esgData.governanceScore}</p>
                      <p>ESG Score: {esgData.ESGScore}</p>
                      <p>ESG Risk Rating: {EsgRiskRating?.ESGRiskRating} ({EsgRiskRating?.year})</p>
                    </ESGData>
                  )}
                </CompanyDetails>
              </CompanyProfile>
              
              {esgBenchmark && (
                <BenchmarkCard>
                  <h3>ESG Benchmark for 2024</h3>
                  <p>Environmental Score: {esgBenchmark.environmentalScore}</p>
                  <p>Social Score: {esgBenchmark.socialScore}</p>
                  <p>Governance Score: {esgBenchmark.governanceScore}</p>
                  <p>ESG Score: {esgBenchmark.ESGScore}</p>
                </BenchmarkCard>
              )}
              <ChartContainer>
                {/* <LineChart data={chartData} lineColor='#AC544C'/> */}
                <LineChart labels={labels} data={chartData} symbol={selectedCompany?.symbol} />
              </ChartContainer>             
            </>
            
          )}
          </GridContainer>
          
        {/* </ContentWrapper> */}
        {selectedCompany && selectedCompany.profile && selectedCompany.profile.image && (
          <>
        <GridContainer>
        <Card>
        <h3>Free Cash Flow</h3>
        <BarChart data={cashFlowData} label="Free Cash Flow" color='#93f811'/>
      </Card>
      <Card>
        <h3>Total Revenue</h3>
        <BarChart data={revenueData} label="Total Revenue" color='#328ae2'/>
      </Card>
      <Card>
        <h3>Enterprise Value (evToFreeCashFlow)</h3>
        <BarChart data={enterpriseValueData} label="Enterprise Value" color='#f82e16'/>
      </Card>
      <Card>
        <h3>Enterprise Value (Alt)</h3>
        <BarChart data={enterpriseValueAltData} label="Enterprise Value" color='#89084b'/>
      </Card>
      </GridContainer>

      <NewsContainer>
      <Card>
      <NewsSection symbol={symbol1} />
      </Card>
      </NewsContainer>
      </>
      )}
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default CompanySummary;
