import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header/index'; // Ensure this path is correct
import Footer from '../../Components/Common/Footer';

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
  titleColor: "#AC544C",
  textColor: "#f0f0f0",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  padding: 50px;
  text-align: left;
  margin-top: 30px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h2`
  font-size: 2em;
  font-family: 'Lora', serif;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.titleColor};
`;

const Text = styled.p`
  font-family: 'Lora', serif;
  font-size: 1.2em;
  line-height: 1.6;
  color: ${({ theme }) => theme.textColor};
`;

const Services = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   setTheme(mediaQuery.matches ? darkTheme : lightTheme);
  //   const handleChange = (e) => setTheme(e.matches ? darkTheme : lightTheme);
  //   mediaQuery.addEventListener("change", handleChange);
  //   return () => mediaQuery.removeEventListener("change", handleChange);
  // }, []);

  

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header />
        <ContentWrapper>
          <Card>
            <Title>Comprehensive Company-Level Fundamental Analysis</Title>
            <Text>
              VegaVeta provides in-depth company-level fundamental analysis, focusing on financial health,
              business model, industry position, and growth prospects. Our methodical approach examines
              revenue, earnings, cash flow, and balance sheet strength, revealing each company's intrinsic
              value. Using sophisticated analytics and thorough research, we help investors understand a
              company's standing within its industry, identify growth opportunities, and assess financial
              stability. This analysis transforms complex financial data into clear insights, supporting data-
              driven investment decisions and offering a comprehensive view of the competitive investment
              landscape.
            </Text>
          </Card>

          <Card>
            <Title>Premier Economic Data Service</Title>
            <Text>
              VegaVeta's economic data service offers detailed insights into macroeconomic indicators,
              trends, and forecasts. Covering GDP growth rates, inflation, employment statistics, and trade
              balances, we provide a comprehensive view of the global economic landscape. Designed for
              investors and analysts, our service helps you understand the nuances of the macroeconomic
              environment and its impact on investment opportunities and risk assessment. Our data and
              analysis support strategic, informed investment decisions by revealing broader market
              dynamics, ensuring you stay ahead in the evolving economic landscape.
            </Text>
          </Card>

          <Card>
            <Title>Interactive Chart Creation</Title>
            <Text>
              VegaVeta empowers clients to create their own interactive charts using our extensive financial
              and economic data. With access to a vast database, users can customize charts to visualize
              specific metrics, trends, and indicators relevant to their investment strategies. This interactive
              tool allows for dynamic analysis, helping clients uncover insights tailored to their unique needs.
              Whether you're tracking market movements, comparing company performances, or analyzing
              economic indicators, VegaVeta's platform offers the flexibility and depth required for detailed,
              data-driven decision-making.
            </Text>
          </Card>

          <Card>
            <Title>In-Depth Portfolio Analysis</Title>
            <Text>
              VegaVeta’s portfolio analysis service provides a thorough evaluation of your investment
              portfolio, beyond traditional performance metrics. Using advanced analytics like Monte Carlo
              simulations, Sharpe Ratio assessments, and Value at Risk (VaR) calculations, we
              systematically analyze risk, return, and asset correlation. Our insights, tailored to your
              investment goals and risk tolerance, help you make strategic adjustments to enhance your
              portfolio's resilience and growth potential. This analysis optimizes diversification, identifies
              opportunities, and manages risks, aligning investments with long-term objectives.
            </Text>
          </Card>

          <Card>
            <Title>Regional Analysis Services</Title>
            <Text>
              VegaVeta's regional analysis services offer deep insights into specific geographic markets. We
              evaluate economic and social factors influencing regional markets, providing a clear
              understanding of unique opportunities and risks. By examining local economic conditions,
              demographic trends, and regional policies, we offer a nuanced perspective on market
              complexities. Whether exploring emerging markets or established regions, our tailored insights
              enable informed decisions, helping you discover hidden opportunities and foresee regional
              risks.
            </Text>
          </Card>

          <Card>
            <Title>Weekly Chartbook</Title>
            <Text>
              VegaVeta's weekly chartbook provides up-to-date graphs, offering a visual analysis of market
              trends and performance. Delivered in a convenient PDF format, the chartbook highlights key
              market patterns, supporting data-driven investment decisions. Each edition enables clients to
              integrate the latest data into reports and presentations, giving a concise overview of market
              movements. This visual tool helps identify emerging opportunities and assess market
              performance, refining investment strategies with accurate insights.
            </Text>
          </Card>

          <Card>
            <Title>Market Insights</Title>
            <Text>
              VegaVeta offers market insights covering a wide range of financial metrics and indicators. Our
              analyses explore key economic indicators, industry trends, and financial performance metrics,
              providing actionable information for investment decisions. By staying up-to-date with our
              reports, you can anticipate market movements, seize opportunities, and mitigate risks. Designed
              for investors seeking detailed information, VegaVeta's insights ensure strategic choices and a
              competitive edge in dynamic financial markets.
            </Text>
          </Card>

          <Card>
            <Title>Securities Recommendations</Title>
            <Text>
              With a rigorous approach to securities analysis, VegaVeta provides recommendations on well-
              researched, unbiased picks across equities, bonds, and other securities. Combining
              fundamental and technical analysis with our proprietary insights, we identify securities with the
              potential for superior returns. Whether you are a seasoned investor or new to the markets, our
              recommendations are designed to help you build a robust, high-performing investment portfolio.
            </Text>
          </Card>

          <Card>
            <Title>Advanced Forecasting Methods</Title>
            <Text>
              Utilizing state-of-the-art predictive models and artificial intelligence, VegaVeta’s advanced
              forecasting methods offer unprecedented accuracy in market predictions. This service is
              designed for investors looking to gain a competitive advantage through early identification of
              market trends, price movements, and potential investment opportunities. Our sophisticated
              algorithms analyze vast datasets to provide you with actionable forecasts, helping you to
              optimize your investment strategy.
            </Text>
          </Card>

          {/* <Card>
            <Title>Research Reports</Title>          
          </Card> */}
        </ContentWrapper>
        
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default Services;
