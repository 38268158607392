// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mb-0{
    padding-right: 150px;
}
.me-3{
    background-color: #AC544C;
}
.loginColor{
    color: #AC544C;
}
.btn-link {
    background-color: white transparent;
    padding-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/login.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;AACxB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;AAClB;AACA;IACI,mCAAmC;IACnC,iBAAiB;AACrB","sourcesContent":["\n.mb-0{\n    padding-right: 150px;\n}\n.me-3{\n    background-color: #AC544C;\n}\n.loginColor{\n    color: #AC544C;\n}\n.btn-link {\n    background-color: white transparent;\n    padding-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
