// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-bullets {
    list-style-type: none; /* Remove default bullet points */
    padding-left: 0; /* Optional: remove padding */
  }
  
  .custom-bullets li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 0; /* Optional: adjust padding */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AboutUs/index.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB,EAAE,iCAAiC;IACxD,eAAe,EAAE,6BAA6B;EAChD;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,eAAe,EAAE,6BAA6B;EAChD","sourcesContent":[".custom-bullets {\n    list-style-type: none; /* Remove default bullet points */\n    padding-left: 0; /* Optional: remove padding */\n  }\n  \n  .custom-bullets li {\n    margin-bottom: 10px;\n    position: relative;\n    padding-left: 0; /* Optional: adjust padding */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
