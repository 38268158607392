import React from 'react';
import { Line } from 'react-chartjs-2';
// import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // zoomPlugin
);

const AreaChart = ({ data }) => {
  const chartData = {
    labels: data.map(chart => chart.date),
    datasets: [
      {
        label: 'Adjusted Close Price',
        data: data.map(chart => chart.close),
        borderColor: '#AC544C',
        backgroundColor: '#AC544C', // Semi-transparent fill color
        fill: true, // Enable fill below the line
        borderWidth: 2, // Thicker line for better visibility
        pointRadius: 0, // Remove points for a cleaner area chart
        pointHoverRadius: 7,
      },
    ],
  };

  const chartOptions = {
    responsive: true, // Make chart responsive
    plugins: {
      legend: {
        labels: {
          color: '#000',
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `Price: $${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          color: '#ffffff',
          autoSkip: true,
          maxTicksLimit: 10,
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
      y: {
        type: 'linear',
        beginAtZero: false, // Ensure the y-axis starts at zero
        ticks: {
          color: '#000',
          padding: 10,
          callback: function(value) {
            return `$${value}`; // Format Y-axis labels with a dollar sign
          },
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.3)',
          borderColor: 'rgba(255, 255, 255, 0.6)', // Ensure grid lines are visible
          borderDash: [5, 5], // Optional: dashed grid lines
        },
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default AreaChart;
