// src/pages/ChartPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../Header';
import Footer from '../../Components/Common/Footer';
import LineChart from './LineChart';
import BarChart from './BarChart';
import PieChart from './PieChart';
import AreaChart from './AreaChart';
import { toast } from 'react-toastify';
// import 'chartjs-adapter-date-fns';
import { FaSearch ,FaEdit} from 'react-icons/fa'; // Import a search icon from react-icons
import ColumnChart from './ColumnChart';
import CandlestickChart from './CandlestickChart';


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666'
};

const darkTheme = {
  background: 'rgb(31 41 55)',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#ccc'
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top:10px;
`;

const MainLayout = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: fixed;
  top: 60px;
  height: calc(100vh - 50px);
  border-right: 2px solid ${({ theme }) => theme.color};
`;

const SidebarItem = styled.div`
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

// const DateRangeSelector = styled.select`
//   padding: 10px;
//   margin-bottom: 20px;
//   border: 1px solid ${({ theme }) => theme.primary};
//   border-radius: 5px;
// `;

const MainContent = styled.div`
  margin-left: 270px;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative; /* Required for dropdown positioning */
`;

const SearchBarContainer = styled.div`
 
position: relative;
  width: 15%;
  margin-top:60px;
  margin-bottom:20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 40px 10px 15px; /* Added padding to accommodate the icon */
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  transform: translateY(-50%);
  color: ${({ theme }) => theme.primary};
`;

const Dropdown = styled.div`
  position: 20px;
  top: 110px; /* Adjust based on the SearchBar height */
  left: 0;
  width: 80%;
  max-height: 200px; /* Set the maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  z-index: 1000; /* Ensure dropdown appears above other content */
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  flex-grow: 1;
`;

const ChartCard = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height:600px;
  overflow:auto;
  width: 95%;
`;

const CompanyHeader = styled.h1`
  margin-bottom: 20px;
  font-size:1.5em;
  color: ${({ theme }) => theme.primary};
`;

const DateRangeButtonGroup = styled.div`
  display: flex;
  // justify-content: space-around;
  margin-bottom: 10px;
`;

const DateRangeButton = styled.button`
  background: ${({ active }) => (active ? 'green' : '#AC544C')};
  // background: ${({ theme }) => theme.primary};
  border: 1px solid #000;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s, color 0.3s;

  &:last-of-type {
    margin-right: 0;
  }
  
  &:hover {
    background: #0056b3;
    color: #fff;
  }
`;

const ChartList = styled.div`
  margin-top: 20px;
`;
const ChartItem = styled.div`
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.secondary};
  color: white;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const EditButton = styled.button`
  position: fixed;
  right: 20px;
  top: 180px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const ColorInput = styled.input`
  margin-bottom: 10px;
  padding: 5px;
  width: 150px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  cursor: pointer;
`;


const DateRangeSelector = ({ value, onChange }) => (
  <DateRangeButtonGroup>
    {['1d', '30d', '3m','6m', '1y', '5y', 'ytd'].map(range => (
      <DateRangeButton
        key={range}
        active={value === range}
        onClick={() => onChange(range)}
      >
        {range === '1d' ? '1 Day' :
         range === '30d' ? '1 Month' :
         range === '3m' ? '3 Months' :
         range === '6m' ? '6 Months' :
         range === '1y' ? '1 Year' :
         range === '5y' ? '5 Years' :
         'YTD'}
      </DateRangeButton>
    ))}
  </DateRangeButtonGroup>
);

const ChartPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchName, setSearchName] = useState('');
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [charts, setCharts] = useState([]);
  const [showPanel, setShowPanel] = useState(false); // State to show/hide the chart panel
  const [lineColor, setLineColor] = useState('#FF0000'); // Default color is red
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme;
    }
  });

  const [selectedCharts, setSelectedCharts] = useState({
    Candlestick: false,
    Line: false,
    Bar: false,
    Pie: false,
    Area: false,
    Column:false,
    
  });

  const [dateRange, setDateRange] = useState('1y');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (selectedSymbol) {
      fetchChartData(selectedSymbol, dateRange);
    }else{
      handleSearchSelect({symbol:'AAPL',name:"Apple Inc"})
    }
  }, [selectedSymbol, dateRange]);

  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const fetchChartData = async (symbol, range) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=2010-01-01`);
      const data = response.historical.map(item => ({
        date: item.date,
        close: item.adjClose,
        volume: item.volume,
        open: item.open,
        high: item.high,
        low: item.low,
      }));
      
      // Filter data based on selected range
      const now = new Date();
      let startDate;

      switch (range) {
        case '1d':
          startDate = new Date();
          break;
          // startDate.setDate(now.getDate() - 30);
        case '30d':
          startDate = new Date();
          startDate.setDate(now.getDate() - 30);
          break;
        case '3m':
          startDate = new Date();
          startDate.setMonth(now.getMonth() - 3);
          break;
        case '6m':
          startDate = new Date();
          startDate.setMonth(now.getMonth() - 6);
          break;
        case '1y':
          startDate = new Date();
          startDate.setFullYear(now.getFullYear() - 1);
          break;
        case '5y':
          startDate = new Date();
          startDate.setFullYear(now.getFullYear() - 5);
          break;
        case 'ytd':
          startDate = new Date();
          startDate.setFullYear(now.getFullYear() - 10);
          break;        
        default:
          startDate = new Date();
          
      }

      const filteredData = data.filter(item => new Date(item.date) >= startDate);
      
      setCharts(filteredData);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  const togglePanel = () => {
    setShowPanel(!showPanel);
  };

  const addChart = (chartType) => {
    setSelectedCharts((prev) => [...prev, chartType]);
    togglePanel(); // Close the panel after adding the chart
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length > 2) {
      await fetchSearchResults(query);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchSelect = (res) => {
    setSelectedSymbol(res.symbol);
    setSearchTerm('');
    setSearchName(res.name + " - " +res.symbol);
    setSearchResults([]);
    setSelectedCharts(prevState => ({
        ...prevState,
        ['Line']: true
      }));
  };

  const handleCheckboxChange = (chartType) => {
    setSelectedCharts({
      Candlestick: false,
      Line: false,
      Bar: false,
      Pie: false,
      Area: false,
      Column: false,
      [chartType]: true,
    });
  };
  

  const handleDateRangeChange = (e) => {
    setDateRange(e);
  };

  const renderChart = (option) => {
    switch (option) {
      case 'Candlestick':
        return <CandlestickChart data={charts} />;
      case 'Line':
        return <LineChart data={charts} lineColor={lineColor}  />;
      case 'Bar':
        return <BarChart data={charts} />;
      case 'Pie':
        return <PieChart data={charts} />;
      case 'Area':
        return <AreaChart data={charts} />;        
      case 'Column':
        return <ColumnChart data={charts} />;
      
      default:
        return <div style={{ color: '#000' }}>Chart not available</div>;
    }
  };

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
      <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <MainLayout>
          {/* <Sidebar> */}
            {/* <DateRangeSelector value={dateRange} onChange={handleDateRangeChange}>
              <option value="30d">Last 30 days</option>
              <option value="3m">Last 3 months</option>
              <option value="1y">Last 1 year</option>
              <option value="5y">Last 5 years</option>
            </DateRangeSelector> */}
                        
            {/* <SidebarItem>
              <Checkbox type="checkbox" checked={selectedCharts.Candlestick} onChange={() => handleCheckboxChange('Candlestick')} />
              Candlestick
            </SidebarItem> */}
            {/* <SidebarItem>
              <Checkbox type="checkbox" checked={selectedCharts.Line} onChange={() => handleCheckboxChange('Line')} />
              Line
            </SidebarItem> */}
            {/* <SidebarItem>
              <Checkbox type="checkbox" checked={selectedCharts.Bar} onChange={() => handleCheckboxChange('Bar')} />
              Bar
            </SidebarItem>
            <SidebarItem>
              <Checkbox type="checkbox" checked={selectedCharts.Pie} onChange={() => handleCheckboxChange('Pie')} />
              Pie
            </SidebarItem>
            <SidebarItem>
              <Checkbox type="checkbox" checked={selectedCharts.Area} onChange={() => handleCheckboxChange('Area')} />
              Area
            </SidebarItem>
            <SidebarItem>
              <Checkbox type="checkbox" checked={selectedCharts.Column} onChange={() => handleCheckboxChange('Column')} />
              Column
            </SidebarItem> */}
      
            {/* <label htmlFor="colorPicker">Select Line Color: </label>
      <ColorInput
        id="colorPicker"
        type="color"
        value={lineColor}
        onChange={(e) => setLineColor(e.target.value)}
      /> */}
          {/* </Sidebar> */}
          <MainContent>
          <SearchBarContainer>
            <SearchInput
            type="text"
            placeholder="Search for a symbol (e.g., AAPL)"
            value={searchTerm}
            onChange={handleSearchChange}
            />
            <SearchIcon />
        </SearchBarContainer>
        
        <CompanyHeader>{searchName}</CompanyHeader>
        {/* <EditButton onClick={togglePanel}>
          <FaEdit />
        </EditButton> */}
        {/* {searchName != "" ? <DateRangeSelector value={dateRange} onChange={handleDateRangeChange} /> : ""} */}
            
            {searchResults.length > 0 && (
              <Dropdown>
                {searchResults.map(result => (
                  <DropdownItem key={result.symbol} onClick={() => handleSearchSelect(result)}>
                    {result.symbol} - {result.name}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
            {/* {loading && <div>Loading data...</div>} */}
            {searchName ? 
            <ChartCard>
  {Object.keys(selectedCharts).map(chartType => (
    selectedCharts[chartType] ? (
      <div key={chartType}>
        <h3>{chartType}</h3>
        {renderChart(chartType)}
      </div>
    ) : null
  ))}
</ChartCard> : null}

          </MainContent>
        </MainLayout>
        <Footer />
      </Container>
      
      {/* <ChartList>
            {['Line', 'Bar', 'Pie', 'Area', 'Candlestick', 'Column'].map((chartType) => (
              <ChartItem key={chartType} onClick={() => addChart(chartType)}>
                {chartType} Chart
              </ChartItem>
            ))}
          </ChartList> */}
    </ThemeProvider>
  );
};

export default ChartPage;
