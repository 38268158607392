// Modal.js

import React from 'react';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  max-height: 50%;
  overflow-y: auto;
  z-index: 101;
  color: #000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e46f9a;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
`;

const Modal = ({ children, closeModal }) => {
  return (
    <>
      <ModalBackdrop onClick={closeModal} />
      <ModalContent>
        {/* <CloseButton onClick={closeModal}>X</CloseButton> */}
        {children}
      </ModalContent>
    </>
  );
};

export default Modal;
