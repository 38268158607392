import React, { useEffect, useState,  } from "react";
import styled from "styled-components";
import logo from "../../assets/images/vegaveta/vegaveta_header"; // Update this to the correct path of your image
import LoginModal from "../Login/LoginModal"; // Update the path to your LoginModal component
import { Navigate, useLocation,useNavigate } from "react-router-dom";
import ThemeToggle from "../../Layouts/ThemeToggle";
import { FaBars, FaUser } from 'react-icons/fa'; 
import { useSelector, useDispatch } from 'react-redux';
import { logoutUserSuccess } from "../../slices/auth/login/reducer";
import { useUser, useClerk } from '@clerk/clerk-react';
import { FaBell, FaMoon,FaCaretDown } from 'react-icons/fa';
import axios from 'axios';

// Styled components
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(55 65 81);
  z-index: 1000;
  box-sizing: border-box;
  
  border-bottom: 1px solid #AC544C;

  @media (max-width: 768px) {
    padding: 10px 15px;
    position: absolute;
  }
`;

const Logo = styled.img`
  width: 60px;
  // margin-left: 10%;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 50px;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled.button`
  background-color: #AC544C;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #b65e4d;
  }
`;

const Nav = styled.nav`
  display: contents;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  

  @media (max-width: 768px) {
    display: ${props => props.isNavOpen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: #222122;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 999;
  }
`;


const NavLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: 1.2em;
  position:relative;
  &:hover {
    color: #AC544C;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 15px;
  }
`;

const SubNav = styled.div`
  display: flex;
  margin-top:30px;
  margin-left:0px;
  // margin-left:10px;
  flex-direction: column; /* Horizontal layout */
  justify-content: flex-start;
  background-color: rgb(55 65 81);
  padding: 1px;
  position: absolute;
  top: 50%; /* Positioning it below the NavLink */
  left: 0;
  min-width: 100px;
  border-top: 1px solid #AC544C;
  // border-bottom: 1px solid #AC544C;
  // border-right: 1px solid #AC544C;
  // border-left: 1px solid #AC544C;
  z-index: 998;
  
`;

const SubNavLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 10px 10px 15px 10px;  
  padding:10px ;
  white-space: nowrap;
  
  font-size: 1em;
  // border-bottom: 1px solid #AC544C;
  // border: 1px solid #AC544C;
  cursor: pointer;
  // min-width:200px;
  &:hover {
    color: #AC544C;
  }
`;
const LogoLink = styled.a`
  // margin-left: 20%;
  text-decoration: none;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const HamburgerMenu = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5em;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const SearchBar = styled.input`
  width: 15%;
  padding: 10px;
  font-size: 1.2em;
  margin-bottom: 0px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  position: relative;
`;

const Suggestions = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.primary};
  max-height: 150px;
  overflow-y: auto;
  width: 15%; /* Matches the SearchBar width */
  position: absolute;
  top: calc(100% + 2px); 
  margin-left: 130px; 
  left: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.background};
  border-radius: 0 0 5px 5px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: white;
  }
`;


const NavWrapper = styled.div`
  position: relative; /* Make sure the parent has relative positioning */
`;

const Header = ({ layoutModeType, onChangeLayoutMode, onCompanySelect }) => {
  
  
  const location = useLocation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isNewsSubMenuVisible, setIsNewsSubMenuVisible] = useState(false);
  // const [activeMenu, setActiveMenu] = useState(null);
  const [redirectToMarket, setRedirectToMarket] = useState(false);  
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { isSignedIn, user } = useUser();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [query, setQuery] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const username = useSelector((state) => {
    console.log(state);
    
  }); // Adjust path as needed

  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setSelectedCompany(value);

    if (value) fetchSearchResults(value);
    else setSearchResults([]);
  };

  const handleSelectCompany = (company) => {        
    setQuery(company.companyName);
    setSelectedCompany(company);
    setSearchResults([]);
    onCompanySelect(company);
    
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  console.log(this);
  

  const isAuthenticated = useSelector((state) =>{ 

    return isSignedIn });

    const [activeMenu, setActiveMenu] = useState(() => {
      const currentPath = location.pathname.slice(1) || '';
      const keywordToCategory = {
        political: 'global',
        economic: 'global',
        socioeconomic: 'global',
        stock: 'news',
        crypto: 'news',
        forex: 'news',
        general: 'news',
        overall: 'portfolio',
        clients: 'portfolio',
        portfolio: 'portfolio',
    };
    if(currentPath === 'summary' ){
      setShowSearchBar(true);
    }
      // const savedState = localStorage.getItem('headerState',currentPath);      
      
      // return  keywordToCategory[currentPath];
    });

    

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };


  const dispatch = useDispatch();

  
  if(isSignedIn &&  location.pathname === '/'){
    return <Navigate to="/market" />;  // Navigate to the market page
  }
  if (redirectToMarket) {
    window.location.reload();
    return <Navigate to="/market" />;  // Navigate to the market page
}




const toggleSubMenu = (menuName) => {
  setActiveMenu(menuName)
};

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout1 = () => {
    signOut();
  };  


  const subMenus = {
    news: [
      { name: "General", link: "/general" },
      { name: "Stock", link: "/stock" },
      { name: "Crypto", link: "/crypto" },
      { name: "Forex", link: "/forex" },
    ],
    portfolio: [
      { name: "Overall", link: "/overall" },
      { name: "Clients", link: "/clients" },
      { name: "Portfolio", link: "/portfolio" },
      
    ],
    pricing: [
      { name: "Basic", link: "/pricing/basic" },
      { name: "Premium", link: "/pricing/premium" },
      { name: "Enterprise", link: "/pricing/enterprise" },
    ],
    global: [
      { name: "Political", link: "/political" },
      { name: "Economic", link: "/economic" },
      { name: "Socioeconomic", link: "/socioeconomic" },
      
    ],
    company: [
      { name: "Summary", link: "/summary" },
      { name: "Income Statement", link: "/incomestatement" },
      { name: "Balance Sheet", link: "/bakancesheet" },
      
    ],
    
  };

  const doRedirect = () => {
    navigate('/political');  // Redirect to the "/political" route
  };
  

  return (
    <HeaderContainer >
      {isAuthenticated &&
      <LogoLink href="/market">
        <Logo src={logo} alt="Logo" />
      </LogoLink>}
      {!isAuthenticated &&
      <LogoLink href="/">
        <Logo src={logo} alt="Logo" />
      </LogoLink>}

      {isAuthenticated && showSearchBar &&
      <>
      <SearchBar
          type="text"
          placeholder="Search by company name or symbol..."
          value={query}
          onChange={handleSearchChange}
        />
        {searchResults.length > 0 && (
          <Suggestions>
            {searchResults.map((result) => (
              <SuggestionItem
                key={result.symbol}
                onClick={() => handleSelectCompany(result)}
              >
                {result.name} ({result.symbol})
              </SuggestionItem>
            ))}
          </Suggestions>
        )}
      </>
      
      }
      <HamburgerMenu onClick={toggleNav}>
        <FaBars />
      </HamburgerMenu>
                
      <Nav isNavOpen={isNavOpen}>
        {!isAuthenticated && <NavLink href="/">Home</NavLink> }
        {isAuthenticated && <NavLink href="/market" onMouseOver={() => toggleSubMenu("")}><i class="fas fa-chart-line fa-xs"></i>&nbsp;Market</NavLink>}
        <NavWrapper>        
        {activeMenu === 'news' && (
        <SubNav>
          {subMenus[activeMenu].map((subMenuItem) => (
            <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
              {subMenuItem.name}
            </SubNavLink>
          ))}
        </SubNav>
        )}
        
        {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("news")}><i class="fas fa-newspaper fa-xs"></i>&nbsp;News  <FaCaretDown/></NavLink>}
        </NavWrapper>        
        <NavWrapper>        
        {activeMenu === 'company' && (
        <SubNav>
          {subMenus[activeMenu].map((subMenuItem) => (
            <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
              {subMenuItem.name}
            </SubNavLink>
          ))}
        </SubNav>
        )}
        
        
        {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("company")} ><i class="fas fa-building fa-xs"></i>&nbsp;Company  <FaCaretDown /></NavLink>}
        </NavWrapper>        
        
        {!isAuthenticated && <NavLink href="/services" onMouseOver={() => toggleSubMenu("")}>Services</NavLink>}
        {!isAuthenticated && <NavLink href="/pricing">Pricing</NavLink>}
        {!isAuthenticated && <NavLink href="/about">About Us</NavLink>}
        
       
        {isAuthenticated && <NavLink href="/chart" onMouseOver={() => toggleSubMenu("")}><i class="fas fa-chart-bar fa-sm"></i>&nbsp;Charts</NavLink>}
        <NavWrapper>        
        {activeMenu === 'portfolio' && (
        <SubNav>
          {subMenus[activeMenu].map((subMenuItem) => (
            <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
              {subMenuItem.name}
            </SubNavLink>
          ))}
        </SubNav>
        )}
        
               
        {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("portfolio")}><i class="fas fa-wallet fa-xs"></i>&nbsp;Portfolio  <FaCaretDown /></NavLink>}
        </NavWrapper>          
      
        
        
        <NavWrapper>        
        {activeMenu === 'global' && (
        <SubNav>
          {subMenus[activeMenu].map((subMenuItem) => (
            <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
              {subMenuItem.name}
            </SubNavLink>
          ))}
        </SubNav>
        )}
      {isAuthenticated && <NavLink href="" onMouseOver={() => toggleSubMenu("global")}>
      <i class="fas fa-globe earth-icon fa-xs"></i>&nbsp;Global  <FaCaretDown /></NavLink>}
        
          </NavWrapper>
        {isAuthenticated && <NavLink href="/articles" onMouseOver={() => toggleSubMenu("")}><i class="fas fa-file-alt fa-xs"></i>&nbsp;Articles</NavLink>}
        
        {!isAuthenticated &&
        <ButtonContainer>
          <Button>Start your free trial</Button>
        </ButtonContainer>}
        
<div>
      {!isAuthenticated  &&
        <NavLink onClick={() => window.Clerk.openSignIn()}>Login</NavLink>
      }      
    </div> 
{isAuthenticated && (
  <div className="d-flex align-items-center" onMouseOver={() => toggleSubMenu("")}>
    
    <ThemeToggle
  layoutMode={layoutModeType}
  onChangeLayoutMode={onChangeLayoutMode}
/>
    {/* Notification Bell */}
    <div className="position-relative me-4" >
      <FaBell size={20} style={{ color: '#fff' }} />
      <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle">
        3
      </span>
    </div>
    
    {/* Profile Avatar with Dropdown */}
    <div className="position-relative" style={{background:'#fff', padding:'10px', color:'#000'}}>
      <div
        className="d-flex align-items-center"
        onClick={toggleDropdown}
        style={{ cursor: 'pointer' }}
      >
        <div
          className="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center me-2"
          style={{ width: '40px', height: '40px' }}
        >
          {/* Placeholder Avatar or Image */}
          <FaUser />
          
        </div>
        {user.firstName.toLocaleUpperCase()}
      </div>

      {/* Dropdown Menu */}
      {showDropdown && (
        <div
          className="dropdown-menu show position-absolute end-0 mt-2 p-2 shadow-lg"
          style={{ minWidth: '130px', color: '#fff', background: '#000' }}
        >
          <a href="/settings" className="dropdown-item" style={{ color: '#fff',background: '#000' }}>
            Settings
          </a>
          <div className="dropdown-divider" style={{ borderBlockColor: '#b65e4d' }}></div>
          <button className="dropdown-item" onClick={handleLogout1} style={{ color: '#fff' ,background: '#000'}}>
            Logout
          </button>
        </div>
      )}
      
    </div>
    
  </div>
)}
      </Nav>
      
      <LoginModal isOpen={isLoginModalOpen} toggle={toggleLoginModal} />  
        </HeaderContainer>
  );
};

export default Header;
