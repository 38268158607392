import React from 'react';
import { VectorMap } from '@south-paw/react-vector-maps';
import styled from 'styled-components';

// Styled container for the map
const MapContainer = styled.div`
  width: 100%;
  height: 500px; // Adjust height as needed
`;

const GeographicMap = ({ data }) => {
  // Transform the data to create a mapping of country codes to revenue
  const countryData = {};
  Object.entries(data).forEach(([date, segments]) => {
    Object.entries(segments).forEach(([segment, revenue]) => {
      if (segment === "Americas Segment") {
        countryData["USA"] = (countryData["USA"] || 0) + revenue; // USA for Americas Segment
        countryData["CAN"] = (countryData["CAN"] || 0) + revenue * 0.3; // Assume some revenue for Canada
      } else if (segment === "Europe Segment") {
        countryData["GBR"] = (countryData["GBR"] || 0) + revenue; // UK for Europe Segment
        countryData["FRA"] = (countryData["FRA"] || 0) + revenue * 0.3; // Assume some revenue for France
      } else if (segment === "Greater China Segment") {
        countryData["CHN"] = (countryData["CHN"] || 0) + revenue; // China
      } else if (segment === "Japan Segment") {
        countryData["JPN"] = (countryData["JPN"] || 0) + revenue; // Japan
      }
      // Add more segments as needed
    });
  });

  // Map styles
  const mapStyles = {
    default: {
      fill: '#B5C9D5',
      stroke: '#fff',
      strokeWidth: 0.5,
    },
    hover: {
      fill: '#A0C1D6',
    },
    pressed: {
      fill: '#7DA1B5',
    },
  };

  return (
    <MapContainer>
      <VectorMap
        map="world_mill"
        backgroundColor="#EAEAEA"
        zoomOnScroll={false}
        containerStyle={{
          width: '100%',
          height: '100%',
        }}
        onRegionClick={(event, code) => {
          console.log(`Clicked region: ${code}`);
        }}
        style={mapStyles}
        regionStyle={{
          // Fill each country with the corresponding revenue value
          fill: (code) => {
            const revenue = countryData[code] || 0;
            return revenue > 0 ? '#3D9970' : '#B5C9D5'; // Example color based on revenue
          },
        }}
      />
    </MapContainer>
  );
};

export default GeographicMap;
