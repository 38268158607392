import React from 'react';
import Chart from 'react-apexcharts';

const CandlestickChart = ({ data }) => {
  // Map data for candlestick chart
  const candlestickData = data.map(d => ({
    x: new Date(d.date),
    y: [d.open, d.high, d.low, d.close],
  }));

  // Map data for volume chart
  const volumeData = data.map(d => ({
    x: new Date(d.date),
    y: d.volume,
  }));

  // Options for candlestick chart
  const candlestickOptions = {
    chart: {
      id: 'candlestick-chart',
      type: 'candlestick',
      height: 350,
      toolbar: {
        autoSelected: 'pan',
        show: true,
      },
    },
    title: {
      text: 'Candlestick Chart',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
    tooltip: {
      theme: 'dark',
    },
  };

  // Options for volume chart
  const volumeOptions = {
    chart: {
      id: 'volume-chart',
      height: 100,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        colors: {
          ranges: [
            { from: -1000, to: 0, color: '#F15B46' },
            { from: 1, to: 10000, color: '#00A100' },
          ],
        },
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
        theme: 'dark',
      },
  };

  return (
    <div id="candlestick-volume-chart" style={{ position: 'relative', width: '100%' }}>
      <div id="candlestick-chart-container" style={{ width: '100%' }}>
        <Chart
          options={candlestickOptions}
          series={[{ data: candlestickData }]}
          type="candlestick"
          height={350}
        />
      </div>
      <div id="volume-chart-container" style={{ width: '100%', marginTop: '-10px', marginLeft:'50px' }}>
        <Chart
          options={volumeOptions}
          series={[{ name: 'Volume', data: volumeData }]}
          type="bar"
          height={100}
        />
      </div>
    </div>
  );
};

export default CandlestickChart;
