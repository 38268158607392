import {
    SMA,
    EMA,
    WMA,
    MACD,
    RSI,
    ROC,
    WilliamsR,
    BollingerBands,
    ATR,
    KeltnerChannels,
    OBV,
    ADL,
    ADX,
    ForceIndex,
    FibonacciRetracement,
} from 'technicalindicators';

/**
 * Calculate Simple Moving Average
 * @param {Array} data - Array of prices
 * @param {number} period - The number of periods for the moving average
 * @returns {Array} - Array of SMA values
 */
export const calculateSMA = (data, period=12) => {
    // Extract closing prices from the input data
    const closingPrices = data.map(item => item.close).filter(price => typeof price === 'number' && !isNaN(price));

    // Check if there are enough prices to calculate SMA
    if (closingPrices.length < period) {
        console.warn('Not enough data to calculate SMA. Expected at least', period, 'but got', closingPrices.length);
        return Array(closingPrices.length).fill(null); // Return an array of nulls if insufficient data
    }

    // Calculate and return the SMA
    return SMA.calculate({ period, values: closingPrices });
};

/**
 * Calculate Exponential Moving Average
 * @param {Array} data - Array of prices
 * @param {number} period - The number of periods for the moving average
 * @returns {Array} - Array of EMA values
 */
export const calculateEMA = (data, period = 12) => {
    // Extract closing prices from data
    console.log(data);
    
    const closingPrices = data.map(item => item.close).filter(price => typeof price === 'number' && !isNaN(price));

    // Check if there are enough prices to calculate EMA
    // if (closingPrices.length < period) {
    //     console.warn('Not enough data to calculate EMA. Expected at least', period, 'but got', closingPrices.length);
    //     return Array(closingPrices.length).fill(NaN); // Return an array of NaNs if insufficient data
    // }

    // Calculate and return the EMA
    return EMA.calculate({ period, values: closingPrices });
};


/**
 * Calculate Weighted Moving Average
 * @param {Array} data - Array of prices
 * @param {number} period - The number of periods for the moving average
 * @returns {Array} - Array of WMA values
 */
export const calculateWMA = (data, period=12) => {
    // Extract closing prices from the input data
    const closingPrices = data.map(item => item.close).filter(price => typeof price === 'number' && !isNaN(price));

    // Check if there are enough prices to calculate WMA
    if (closingPrices.length < period) {
        console.warn('Not enough data to calculate WMA. Expected at least', period, 'but got', closingPrices.length);
        return Array(closingPrices.length).fill(null); // Return an array of nulls if insufficient data
    }

    // Calculate and return the WMA
    return WMA.calculate({ period, values: closingPrices });
};
/**
 * Calculate Moving Average Convergence Divergence
 * @param {Array} data - Array of prices
 * @returns {Object} - MACD values with signal and histogram
 */
export const calculateMACD = (data) => {
    // Extract closing prices from the input data
    const closingPrices = data
        .map(item => item.close)
        .filter(price => typeof price === 'number' && !isNaN(price));
    
    console.log('Closing Prices:', closingPrices); // Check extracted prices

    // Check if there are enough prices to calculate MACD
    // if (closingPrices.length < 12)package.json

    // Calculate MACD
    const macdValues = MACD.calculate({
        values: closingPrices,
        fastPeriod: 12,
        slowPeriod: 26,
        signalPeriod: 9,
    });

    console.log('MACD Values:', macdValues); // Debugging line

    // Map MACD values to include corresponding dates (starting from the 26th entry)
    const result = macdValues.map((macdValue, index) => ({
        date: data[index + 25].date, // Adjust index for date
        macd: macdValue.MACD,
        signal: macdValue.signal,
        histogram: macdValue.histogram,
    }));

    console.log('Final MACD Result:', result); // Final output check

    return result;
};


/**
 * Calculate Relative Strength Index
 * @param {Array} data - Array of price objects with close property
 * @param {number} period - The number of periods for the RSI
 * @returns {Array} - Array of RSI values
 */
export const calculateRSI = (data, period = 3) => {
    const closingPrices = data.map(item => item.close);
    if (closingPrices.length < period) {
        console.warn('Not enough data to calculate RSI. Expected at least', period, 'but got', closingPrices.length);
        return Array(closingPrices.length).fill(null);
    }
    return RSI.calculate({ values: closingPrices, period });
};

/**
 * Calculate Rate of Change
 * @param {Array} data - Array of prices
 * @param {number} period - The number of periods for the ROC
 * @returns {Array} - Array of ROC values
 */
export const calculateROC = (data, period) => {
    return ROC.calculate({ values: data });
};

/**
 * Calculate Williams %R
 * @param {Array} data - Array of price objects with high, low, and close properties
 * @param {number} period - The number of periods for Williams %R
 * @returns {Array} - Array of Williams %R values
 */
export const calculateWilliamsR = (data, period) => {
    const high = data.map(d => d.high);
    const low = data.map(d => d.low);
    const close = data.map(d => d.close);
    return WilliamsR.calculate({ high, low, close, period });
};

/**
 * Calculate Bollinger Bands
 * @param {Array} data - Array of prices
 * @param {number} period - The number of periods for the Bollinger Bands
 * @param {number} stdDev - The number of standard deviations
 * @returns {Object} - Bollinger Bands values
 */
// import { BollingerBands } from 'technicalindicators'; // Ensure you have the correct import

export const calculateBollingerBands = (data, column='close', window = 20, numStdDev = 2, roundingInteger = 2) => {
    // Validate input parameters
    if (roundingInteger !== null && !(0 < roundingInteger && roundingInteger < 7)) {
        console.error("Error: rounding_integer must be between 1 and 6 (inclusive)");
        return null;
    }

    const columnData = data.map(item => item[column]);

    if (!Array.isArray(columnData) || columnData.length === 0) {
        console.error("Error: The specified column must result in a valid array");
        return null;
    }

    const dataLength = columnData.length;

    if (!Number.isInteger(window) || window <= 0 || window > dataLength) {
        console.error(`Error: Window must be a positive integer less than or equal to the data length (${dataLength})`);
        return null;
    }

    if (typeof numStdDev !== "number" || numStdDev <= 0 || numStdDev > 3) {
        console.error(`Error: Number of standard deviation must be a positive number less than or equal to 3 (got ${numStdDev})`);
        return null;
    }

    // Calculate the rolling mean (middle band) and rolling standard deviation
    const rollingMean = [];
    const rollingStd = [];
    const bollingerBandsData = [];
    let resUp=[],resMid=[],resLow = [];

    for (let i = 0; i < dataLength; i++) {
        const start = Math.max(0, i - window + 1);
        const end = i + 1;

        const windowValues = columnData.slice(start, end);

        const mean = windowValues.reduce((sum, value) => sum + value, 0) / windowValues.length;
        rollingMean.push(parseFloat(mean.toFixed(roundingInteger)));

        const stdDev = Math.sqrt(windowValues.map(value => Math.pow(value - mean, 2)).reduce((sum, value) => sum + value, 0) / windowValues.length);
        rollingStd.push(parseFloat(stdDev.toFixed(roundingInteger)));

        // Calculate upper and lower bands
        const upperBand = mean + (stdDev * numStdDev);
        const lowerBand = mean - (stdDev * numStdDev);
        resUp.push(upperBand);
        resMid.push(mean);
        resLow.push(lowerBand);

        bollingerBandsData.push({
            Data: parseFloat(columnData[i].toFixed(roundingInteger)),
            "middleBand": mean,
            "upperBand": upperBand,
            "lowerBand": lowerBand
        });
    }

    console.log(bollingerBandsData,'bd--');
    
    return {upperBand: resUp, middleBand: resMid, lowerBand: resLow};
}

// export const calculateBollingerBands = (data, period = 20, stdDev = 2) => {
//   // Ensure data is provided and has enough length
//   if (!data || data.length < period) {
//     return { upperBand: [], middleBand: [], lowerBand: [] };
//   }

//   // Extract the closing prices from the data
//   const closingPrices = data.map(item => item.close);
//   console.log(closingPrices, 'closing prices');

//   // Calculate Bollinger Bands using the technical indicators library
//   const bollingerData = BollingerBands.calculate({
//     period: period,
//     stdDev: stdDev,
//     values: closingPrices,
//   });
//   console.log(bollingerData, 'Bollinger Bands data');

//   // Extract the middle, upper, and lower bands from the calculation
//   const middleBand = bollingerData.map(band => band.middle);
//   const upperBand = bollingerData.map(band => band.upper);
//   const lowerBand = bollingerData.map(band => band.lower);
//   console.log('Upper Band:', upperBand);
  
//   // Prepend null values for the beginning of the arrays to align with the original data
//   const fullLength = closingPrices.length;
//   const adjustedUpperBand = Array(period - 1).fill(null).concat(upperBand);
//   const adjustedMiddleBand = Array(period - 1).fill(null).concat(middleBand);
//   const adjustedLowerBand = Array(period - 1).fill(null).concat(lowerBand);

//   // Return the calculated bands aligned with the original data length
//   return {
//     upperBand: adjustedUpperBand.slice(0, fullLength),
//     middleBand: adjustedMiddleBand.slice(0, fullLength),
//     lowerBand: adjustedLowerBand.slice(0, fullLength),
//   };
// };
  
/**
 * Calculate Average True Range
 * @param {Array} data - Array of price objects with high, low, close properties
 * @param {number} period - The number of periods for the ATR
 * @returns {Array} - Array of ATR values
 */
export const calculateATR = (data, period) => {
    const high = data.map(d => d.high);
    const low = data.map(d => d.low);
    const close = data.map(d => d.close);
    return ATR.calculate({ high, low, close, period });
};

/**
 * Calculate Keltner Channels
 * @param {Array} data - Array of price objects with high, low, close properties
 * @param {number} period - The number of periods for the Keltner Channels
 * @returns {Object} - Keltner Channel values
 */
export const calculateKeltnerChannels = (data, period) => {
    const high = data.map(d => d.high);
    const low = data.map(d => d.low);
    const close = data.map(d => d.close);
    return KeltnerChannels.calculate({ high, low, close, period });
};

/**
 * Calculate On-Balance Volume
 * @param {Array} data - Array of price objects with volume property
 * @returns {Array} - Array of OBV values
 */
export const calculateOBV = (data) => {
    return OBV.calculate({ values: data.map(d => d.close), volume: data.map(d => d.volume) });
};

/**
 * Calculate Accumulation/Distribution Line
 * @param {Array} data - Array of price objects with high, low, close, and volume properties
 * @returns {Array} - Array of ADL values
 */
export const calculateADL = (data) => {
    return ADL.calculate({ high: data.map(d => d.high), low: data.map(d => d.low), close: data.map(d => d.close), volume: data.map(d => d.volume) });
};

/**
 * Calculate Average Directional Index (ADX)
 * @param {Array} data - Array of price objects with high, low, and close properties
 * @param {number} period - The number of periods for the ADX
 * @returns {Array} - Array of ADX values
 */
export const calculateADX = (data, period) => {
    return ADX.calculate({ high: data.map(d => d.high), low: data.map(d => d.low), close: data.map(d => d.close), period });
};

/**
 * Calculate Force Index
 * @param {Array} data - Array of price objects with close and volume properties
 * @param {number} period - The number of periods for the Force Index
 * @returns {Array} - Array of Force Index values
 */
export const calculateForceIndex = (data, period) => {
    return ForceIndex.calculate({ values: data.map(d => d.close), volume: data.map(d => d.volume), period });
};

/**
 * Calculate Fibonacci Retracement Levels
 * @param {number} high - The highest price
 * @param {number} low - The lowest price
 * @returns {Object} - Fibonacci retracement levels
 */
export const calculateFibonacciRetracement = (high, low) => {
    const diff = high - low;
    return {
        level1: high - 0.236 * diff,
        level2: high - 0.382 * diff,
        level3: high - 0.618 * diff,
        level4: low + 0.618 * diff,
    };
};

// Add more indicators as needed
