import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

const ChartContainer = styled.div`
  max-width: 100%; // Prevents overflow horizontally
  height: 500px; // Sets a fixed height for the chart
  overflow: hidden; // Hides any overflow
  margin-top: 20px;
`;

const LineChart = ({ labels, data, symbol }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: `Adjusted Close Price (${symbol})`,
        data: data,
        borderColor: '#4b9cd3',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to respect container height
    scales: {
      x: {
        title: { display: true, text: 'Date' },
      },
      y: {
        title: { display: true, text: 'Adjusted Close Price' },
        beginAtZero: false,
      },
    },
  };

  return (
    <ChartContainer>
      <h3>Adjusted Close Price</h3>
      <Line data={chartData} options={options} />
    </ChartContainer>
  );
};

export default LineChart;
