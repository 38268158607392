import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Header from '../../pages/Header/index'; // Ensure this path is correct
import Footer from '../../Components/Common/Footer';

// Sample images, replace with your actual image paths
import Image1 from '../../assets/images/vegaveta/faizal.jpg';
import Image2 from '../../assets/images/vegaveta/michelle.jpg';
import Image3 from '../../assets/images/vegaveta/tyler.jpg';
import { useState } from 'react';
import './index.css';
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666'
};

const darkTheme = {
  background: '#222122',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#ccc'
};

const Container = styled.div`
  text-align: left;
  padding-top: 50px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.primary};
`;

const Text = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ImageBox = styled.div`
  flex: 1;
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Caption = styled.p`
  font-size: 1.2em;
  color: ${({ theme }) => theme.captionColor};
  margin-top: 10px;
`;

const AboutUs = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header />
        <ContentWrapper>
          <Section>
            <Title>Our mission is to simplify intelligent investing.</Title>
            <Text>
              Welcome to VegaVeta. At VegaVeta, we are redefining the landscape of investment management by integrating cutting-edge technology with deep financial expertise. Our goal is to empower investors by providing advanced tools and insightful analyses that enhance decision-making and investment performance. We cater to a diverse clientele, ranging from individual investors to large institutions, helping them achieve their financial objectives through intelligent and responsible investing.
            </Text>
          </Section>

          <Section>
            <Title>What is VegaVeta</Title>
            <Text>
              Vega quantifies an option's sensitivity to changes in the underlying asset's volatility, serving as the derivative of the option's value with respect to volatility. Veta measures the rate of change in Vega as time passes. The concept of Vega and Veta cultivate the culture at our company to leverage human expertise and cutting-edge technology to analyze the market.
            </Text>
          </Section>

          <Section>
            <Title>Who We Are</Title>
            <Text>
              <ul class="custom-bullets">
                <li>❖ Faisal AlSaad - Founder</li>
                <li>❖ Michelle Hicks - Researcher</li>
                <li>❖ Tyler Folkman - Customer Acquisition Strategist</li>
              </ul>
            </Text>
            
            <ImageContainer>
              <ImageBox>
                <Image src={Image1} alt="Faisal AlSaad" />
                <Caption>Faisal AlSaad</Caption>
              </ImageBox>
              <ImageBox>
                <Image src={Image2} alt="Michelle Hicks" />
                <Caption>Michelle Hicks</Caption>
              </ImageBox>
              <ImageBox>
                <Image src={Image3} alt="Tyler Folkman" />
                <Caption>Tyler Folkman</Caption>
              </ImageBox>
            </ImageContainer>
          </Section>

          <Section>
            <Title>Our Approach</Title>
            <Text>
              VegaVeta’s approach combines rigorous financial analysis with the latest technological advancements, including our proprietary QuinBridge Dynamic Model. This model exemplifies our commitment to innovation, utilizing artificial intelligence to process and analyze vast datasets, which include not only traditional financial metrics but also economic data and unstructured information sources. The QuinBridge Dynamic Model enables us to provide dynamic forecasting and comprehensive portfolio evaluations, setting new standards in investment analysis and portfolio management.
            </Text>
          </Section>

          <Section>
            <Title>QuinBridge Dynamic Model</Title>
            
            <Text>
              The QuinBridge Dynamic Model is a cornerstone of our analytical framework, designed to integrate and synthesize information from multiple dimensions to deliver a holistic understanding of the financial landscape. This model incorporates five critical elements:
              <ul class="custom-bullets"><br/>
                <li>❖ Financial</li>
                <li>❖ Technical</li>
                <li>❖ Economic</li>
                <li>❖ Political</li>
                <li>❖ Social</li>
              </ul>
              By combining these elements, the QuinBridge Dynamic Model allows us to take a comprehensive approach to financial analysis, providing nuanced insights and sophisticated strategies for modern financial markets.
            </Text>
          </Section>

          <Section>
            <Title>Our Core Values</Title>
            <Text>
              <ul class="custom-bullets"><br/>
                <li><strong>❖ Privacy & Transparency:</strong> At VegaVeta, trust forms the core of our relationships with clients. We protect your data with the highest standards of security and confidentiality, ensuring your privacy is always respected. Transparency is integral to our operations.</li><br/>
                <li><strong>❖ Client-Centric Approach:</strong> Our clients’ success is our primary focus. We strive to understand the unique goals and preferences of each client, delivering personalized solutions and leveraging advanced AI technologies to empower their investment strategies.</li><br/>
                <li><strong>❖ Inclusivity:</strong> We cultivate an environment that embraces and values diverse perspectives and backgrounds, promoting diversity within our team and ensuring fairness in our AI tools and investment strategies.</li><br/>
                <li><strong>❖ Sustainable Innovation:</strong> We are committed to advancing sustainability and innovation in our operations and investment strategies, integrating sustainable practices with cutting-edge technology.</li><br/>
                <li><strong>❖ Synergy:</strong> Collaboration is essential to our success. We foster teamwork and open communication, both within our organization and with our clients and partners.</li>
              </ul>
            </Text>
          </Section>

          <Section>
            <Title>Our Services</Title>
            <Text>
              VegaVeta offers a suite of services designed to cater to all aspects of investment management, from advanced financial data and economic analysis to securities recommendations and portfolio optimization. We enable our clients to navigate complex markets with confidence and insight.
            </Text>
          </Section>

          <Section>
            <Title>Join Us on Your Investment Journey</Title>
            <Text>
              Whether you are seeking to manage a new portfolio, optimize an existing one, or gain comprehensive market insights, VegaVeta is your trusted partner in the journey towards financial success. We invite you to explore our services and see how our innovative approaches can help you achieve your investment goals.
            </Text>
            <Button href="#">Start a Free Trial</Button>
          </Section>
        </ContentWrapper>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default AboutUs;
