// BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data, label, color = '#AC544C' }) => {
  const reversedData = [...data].reverse();

  const chartData = {
    labels: reversedData.map((item) => item.date),
    datasets: [
      {
        label: label,
        data: reversedData.map((item) => item.value),
        backgroundColor: color,
        maxBarThickness: 12,        
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        barPercentage: 0.5,        // Adjusts bar thickness within the category width
        categoryPercentage: 0.6,   // Adjusts spacing between bars
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
